.tyresWrapper {
    margin-top: 70px;
    padding: 0 20px;
}
.tyresWrapper .tyresList {
    display: flex;
    margin-top: 24px;
}
.tyresWrapper .tyresList .tyresListItem {
    width: 260px;
    margin-right: 20px;
}
.tyresWrapper .tyresList .tyresListItem:last-child {
    margin-right: 0;
}
.tyresWrapper .tyresList .tyresListItem img {
    max-width: 100%;
}
.tyresWrapper .tyresList .tyresListItem :global .TransformComponent-module_content__TZU5O {
    height: 198px;
    width: 100%;
    position: relative;
    background-color: #c1c1c1 b3;
}
.tyresWrapper .tyresList .tyresListItem :global .TransformComponent-module_content__TZU5O img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}
