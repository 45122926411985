.backSearchWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #edf2f7;
    height: 50px;
  }
  .adminForm {
    display: flex;
    padding: 20px;
  }
  .inputAdminForm {
    display: flex;
    padding: 20px;
  }
  .btnContainer {
    margin-top: 25px !important;
    display: flex;
    width: 10%;
  }
  .inputItem {
    width: 25%;
    margin: 0px 10px 20px 10px !important;
  }
  .inputRegNo {
    width: 25%;
    margin: 0px 10px 20px 10px !important;
  }
  .errorText {
    color: red;
    font-size: 12px;
    font-weight: normal;
  }

  .storeConfigLabel{
    color: rgba(117, 117, 117, 1);
    font-weight: 600;
    line-height: 35px;
    letter-spacing: 0.02em;
    margin-bottom: 8px;

  }

  .uploadVideo{
    /* width: 25%; */
    margin-left: 10px !important;
    margin-bottom: 10px;
  }


  