.mailWrapper {
  margin: 50px 25px 25px 25px;
}
.mailWrapper .tableWrapper thead {
  background-color: rgba(214, 210, 210, 0.699);
  padding: 10px;
}
.mailWrapper .tableWrapper tr th {
  width: 50%;
  padding: 15px;
}
.mailWrapper .tableWrapper tr td {
  padding: 20px 0px 20px 20px;
  text-align: center;
  border-bottom: 1px solid #bfd7ea;
}
