.documentInfo {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #e3e3e3;
}
.dataWrapper {
  width: 50%;
}
.checkboxlabel {
  display: flex;
}
.checkboxlabel .lableText {
  font-size: 16px;
  color: #141414;
  padding-left: 10px;
}
.newValue {
  font-size: 11px;
  color: crimson;
  font-weight: bold;
}
.peWrappper {
  width: 50%;
  display: flex;
  justify-content: space-between;
}
.peWrappper .dataWrapper {
  width: 100%;
}
.peWrappper .pe {
  margin-top: 5px;
  background-color: #000 2e;
  color: #0059a3;
  font-size: 14px;
  font-style: italic;
  border-radius: 12px;
  padding: 2px 8px;
  min-width: 100px;
}

.infoIcons {
  height: 20px;
  width: 20px;
  margin-bottom: -4px;
  margin-left: 2px;
}