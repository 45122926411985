.carouselTextWrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    margin-top: 70px;
}
.carouselTextWrapper .carouselContent {
    flex: 1 2 40%;
    margin-left: 20px;
    height: 500px;
    overflow: auto;
}
.carouselTextWrapper .carouselContent .text {
    font-size: 12px;
    font-weight: bold;
    color: #a6a6a6;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}
.carouselTextWrapper .carouselContent .text .checkbox {
    margin: 0 5px;
}
.carouselTextWrapper .carouselContent .text .info {
    padding: 0 5px;
    display: flex;
}
.misMatchSection {
    margin-top: 14px;
}
.misMatchSection ul {
    margin: 0;
    padding: 0;
}
.misMatchSection ul li {
    list-style: none;
    align-items: center;
    display: flex;
    padding: 5px 0;
}
.misMatchSection ul li p {
    margin: 0;
    padding: 0px;
    flex: 1 2 50%;
}
.misMatchSection ul li .carsinfo {
    flex: 1 1 50%;
}
.misMatchSection :global .inspection-car-details-comments_documentInfo__38nfH {
    border-bottom: none;
    padding: 5px 0;
}
.misMatchSection :global .inspection-car-details-comments_checkboxlabel__3GpaN {
    display: flex;
    align-items: center;
}
.carousel {
    flex: 1 1 60%;
    width: 60%;
    flex-wrap: wrap;
}
.commentsCarDetails {
    flex: 1 2 40%;
    margin-left: 20px;
    background-color: #f5f5f5;
    padding: 10px;
}
