.customerAuditProcess {
    padding: 0 20px;
}
.customerAuditProcess .customerAuditProcessHeader {
    height: 56px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    box-shadow: 0px 5px 20px rgba(0, 89, 163, 0.1);
}
.customerAuditProcess .inspectionTitle {
    text-align: center;
    margin-top: 25px;
}
.customerAuditProcess .inspectionTitle h1 {
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #465166;
    margin: 15px 0 0 0;
    padding: 0;
}
.customerAuditProcess .appointmentIdSection {
    background-color: #fff;
    border: 1px solid #dde5eb;
    border-radius: 10px;
    padding: 20px 10px;
    margin-top: 24px;
}
.customerAuditProcess .appointmentIdSection ul {
    margin: 0;
    padding: 0;
}
.customerAuditProcess .appointmentIdSection ul li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 2px 0;
}
.customerAuditProcess .appointmentIdSection ul li h3 {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #465166;
    margin: 0;
    padding: 0;
}
.customerAuditProcess .appointmentIdSection ul li span {
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #465166;
}
.customerAuditProcess .title {
    margin: 30px 0 0 0;
    padding: 12px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #465166;
    position: relative;
}
.customerAuditProcess .title::before {
    content: '';
    width: 36px;
    height: 4px;
    background: linear-gradient(225deg, #f69b00 0%, #ed6a00 100%);
    border-radius: 2px;
    position: absolute;
    bottom: 0px;
    left: 0;
}
.customerAuditProcess .detailsSection {
    background-color: rgba(186, 217, 233, 0.2);
    padding: 10px 20px 20px;
    margin-top: 16px;
}
.customerAuditProcess .detailsSection ul {
    margin: 0;
    padding: 0;
}
.customerAuditProcess .detailsSection ul li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
}
.customerAuditProcess .detailsSection ul li.titles span {
    color: #465166;
    font-weight: 500;
}
.customerAuditProcess .detailsSection ul li h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #465166;
    margin: 0;
    padding: 0;
    width: 50%;
}
.customerAuditProcess .detailsSection ul li span {
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: #205c8c;
    width: 50%;
    text-align: center;
}
.customerAuditProcess .detailsSection ul li .valueText {
    color: #ed6a00;
}
.customerAuditProcess .feedbackSection {
    background-color: #ebf4f9;
    border-radius: 5px;
    margin: 23px 0 0 0;
}
.customerAuditProcess .feedbackSection h4 {
    background-color: #0059a3;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #fff;
    margin: 0;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.customerAuditProcess .feedbackSection .radioButtonSection {
    padding: 5px;
}
.customerAuditProcess .feedbackSection .radioButtonSection label {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #465166;
}
.customerAuditProcess .ctaWrapper {
    margin: 35px 0 0 0;
}
.customerAuditProcess .text {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #465166;
    opacity: 0.7;
    position: relative;
    margin: 0;
    padding: 20px 0 30px 20px;
}
.customerAuditProcess .text::before {
    content: '.';
    position: absolute;
    top: 0;
    left: 0;
    color: #0059a3;
    font-size: 60px;
}
@media screen and (min-width: 600px) {
    .customerAuditProcess {
        width: 600px;
        margin: auto;
   }
}
