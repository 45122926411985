.staticImageWrapper {
    position: relative;
}
.staticImageWrapper .zoomWrapper {
    position: absolute;
    left: 10px;
    right: 0;
    bottom: 7px;
    display: flex;
    justify-content: space-between;
}
.staticImageWrapper .zoomWrapper button {
    cursor: pointer;
}
.staticImageWrapper .zoomWrapper button:focus {
    outline: none;
    border: none;
}
.staticImageWrapper .zoomWrapper .rotation {
    margin: 0px;
    background: transparent;
    border: none;
    padding: 0;
}
.staticImageWrapper .zoomWrapper .rotation:focus {
    outline: none;
    border: none;
}
.staticImageWrapper .zoomWrapper .zoomRotationWrapper {
    display: flex;
}
.staticImageWrapper :global .TransformComponent-module_container__3NwNd, .staticImageWrapper :global .TransformComponent-module_content__TZU5O {
    width: 100%;
}
.staticImageWrapper :global .TransformComponent-module_content__TZU5O {
    height: 500px;
    width: 100%;
    position: relative;
    background-color: #c1c1c1 b3;
}
.staticImageWrapper :global .TransformComponent-module_content__TZU5O img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}
.zoomIcon {
    position: absolute;
    left: 10px;
    bottom: 10px;
    z-index: 9;
    background-color: #fff;
    width: 26px;
    height: 26px;
}
.zoomIcon img {
    width: 80%;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
