.pdf {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.pdf img {
  display: block;
}

.pdfInfo {
  text-align: center;
  margin-bottom: 50px;
}

.pdfInfo i {
  font-size: 12px;
}