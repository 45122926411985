.documentInfo {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid #e3e3e3;
}
.documentInfo:last-child {
    border-bottom: none;
}
.dataWrapper {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
}
.checkboxlabel {
    display: flex;
}
.checkboxlabel .lableText {
    font-size: 16px;
    color: #141414;
    padding-left: 10px;
}
.newValue {
    font-size: 11px;
    color: crimson;
    font-weight: bold;
}
.boldField {
    font-weight: bold;
}
.peWrappper {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
}
.peWrappper .dataWrapper {
    width: 100%;
}
.peWrappper .pe {
    background-color: #000 2e;
    color: #0059a3;
    font-size: 14px;
    font-style: italic;
    border-radius: 12px;
    padding: 2px 8px;
    min-width: 70px;
    margin-top: 5px;
}
