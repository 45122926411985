.performanceWrapper {
    margin-right: 20px;
    position: relative;
    padding: 18px 0;
}
.performanceWrapper .performanceText {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    align-items: center;
    margin: 0;
}
.performanceWrapper .performanceTextWrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 55px;
    z-index: 1;
    display: none;
}
.performanceWrapper .performanceTextWrapper ul {
    margin: 0;
    padding: 10px 20px;
    display: grid;
    grid-template-columns: 60% 20% 20%;
}
.performanceWrapper .performanceTextWrapper ul li {
    list-style: none;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}
.performanceWrapper .performanceTextWrapper .topPerformerHeading {
    background-color: #035d88;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.performanceWrapper .performanceTextWrapper .topers {
    background-color: #004374;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    max-height: 450px;
    overflow: auto;
}
.performanceWrapper .performanceTextWrapper .topers ul {
    border-top: 1px solid #71818d;
    margin: 0;
}
.performanceWrapper .performanceTextWrapper .topers ul li .activeicon {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}
.performanceWrapper .performanceTextWrapper .topers ul li .green {
    background-color: #008000;
}
.performanceWrapper .performanceTextWrapper .topers ul li .red {
    background-color: #f00;
}
.performanceWrapper .performanceTextWrapper .topers ul li .yellow, .performanceWrapper .performanceTextWrapper .topers ul li .lightBlue, .performanceWrapper .performanceTextWrapper .topers ul li .orange {
    background-color: #fcc06e;
    min-width: 15px;
    height: 15px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    display: inline-flex;
    border-radius: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    padding: 5px;
}
.performanceWrapper .performanceTextWrapper .topers ul li .lightBlue {
    background-color: #a9d6ea;
}
.performanceWrapper .performanceTextWrapper .topers ul li .orange {
    background-color: #fa9b71;
}
.performanceWrapper .performanceTextWrapper .topers ul .emailid {
    white-space: nowrap;
    max-width: 20ch;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}
.performanceWrapper:hover .performanceTextWrapper {
    display: block;
}
