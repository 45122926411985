.sellOnlineProcess {
    margin: 0;
    padding: 0 20px;
    max-width: 600px;
}
.sellOnlineProcess .logo {
    display: flex;
    margin: 0 -20px;
    box-shadow: 0px 5px 20px 0px #0059a3 1A;
    justify-content: center;
    padding: 15px;
}
.sellOnlineProcess .sellOnlineProcessHeader {
    height: 56px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    box-shadow: 0px 5px 20px rgba(0, 89, 163, 0.1);
}
.sellOnlineProcess .infoWrapper {
    padding: 0px 20px;
    margin-top: 25px;
    border: 1px solid #dde5eb;
    border-radius: 10px;
    color: #465166;
}
.sellOnlineProcess .infoWrapper div {
    display: flex;
    margin: 10px 0;
    justify-content: space-between;
}
.sellOnlineProcess .infoWrapper .customerName {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
}
.sellOnlineProcess .infoWrapper .appointmentIdLabel {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
}
.sellOnlineProcess .infoWrapper .appointmentId {
    font-size: 14px;
    font-weight: 500px;
}
.sellOnlineProcess .commentContainer .subTitle {
    font-size: 12px;
    line-height: 18px;
    color: #465166;
}
.sellOnlineProcess .commentContainer .commentWrapper {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(209, 236, 255, 0.93);
    margin: 0 -20px;
    padding: 0 20px;
}
.sellOnlineProcess .commentContainer .commentWrapper .comment {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #465166;
    width: 50%;
}
.sellOnlineProcess .commentContainer .commentWrapper .yesValue {
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    color: #f00;
    width: 50%;
}
.sellOnlineProcess .commentContainer .commentWrapper:last-child {
    border: none;
}
.sellOnlineProcess .inspectionTitle {
    text-align: center;
    margin-top: 25px;
}
.sellOnlineProcess .inspectionTitle h1 {
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #465166;
    margin: 15px 0 0 0;
    padding: 0;
}
.sellOnlineProcess .inspectionTitle p {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    color: #465166;
    padding: 0 20px;
}
.sellOnlineProcess .priceSection {
    background-color: #fff;
    border: 1px solid #dde5eb;
    border-radius: 10px;
    padding: 0px 10px;
    margin-top: 24px;
    margin-bottom: 18px;
}
.sellOnlineProcess .priceSection .updatedPriceText {
    position: relative;
    font-family: Roboto;
    font-size: 12px;
    font-style: 400;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: #465166;
    opacity: 0.7;
    padding: 0 33px 0 40px;
}
.sellOnlineProcess .priceSection .updatedPriceText::before {
    content: '';
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    background-image: url('./images/info.svg');
    position: absolute;
    bottom: 10px;
    left: 5px;
    margin: auto;
}
.sellOnlineProcess .priceSection ul {
    margin: 0;
    padding: 0;
}
.sellOnlineProcess .priceSection ul li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 2px 0;
}
.sellOnlineProcess .priceSection ul li:first-child {
    border-bottom: 1px solid #d1ecff ED;
}
.sellOnlineProcess .priceSection ul li h3 {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #465166;
}
.sellOnlineProcess .priceSection ul li h2 {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #006ece;
}
.sellOnlineProcess .priceSection ul li span {
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #465166;
}
.sellOnlineProcess .eligibleSection {
    background-color: #fff;
    border: 1px solid #dde5eb;
    border-radius: 5px;
    padding: 10px 20px 10px 44px;
    margin-top: 24px;
    margin-bottom: 18px;
    position: relative;
}
.sellOnlineProcess .eligibleSection::before {
    content: '';
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    background-image: url('./images/auction.svg');
    position: absolute;
    top: 10px;
    left: 10px;
    margin: auto;
}
.sellOnlineProcess .eligibleSection h3 {
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: #465166;
    margin-top: 0;
}
.sellOnlineProcess .eligibleSection p {
    font-family: Roboto;
    font-size: 12px;
    font-style: 400;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: #465166;
    opacity: 0.7;
    margin: 0;
}
.sellOnlineProcess .title {
    margin: 30px 0 0 0;
    padding: 12px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #465166;
    position: relative;
}
.sellOnlineProcess .title::before {
    content: '';
    width: 36px;
    height: 4px;
    background: linear-gradient(225deg, #f69b00 0%, #ed6a00 100%);
    border-radius: 2px;
    position: absolute;
    bottom: 0px;
    left: 0;
}
.sellOnlineProcess .detailsSection {
    padding: 0px 20px;
    margin: 24px -20px;
}
.sellOnlineProcess .detailsSection .itemWrapper {
    padding: 8px 12px;
    border: 1px solid #dde5eb;
    border-radius: 10px;
    background: rgba(32, 92, 140, 0.04);
    margin-bottom: 12px;
}
.sellOnlineProcess .detailsSection .itemWrapper .titleWrapper {
    display: flex;
    justify-content: space-between;
}
.sellOnlineProcess .detailsSection .itemWrapper .titleWrapper img {
    cursor: pointer;
}
.sellOnlineProcess .detailsSection .itemWrapper h3 {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #465166;
    margin-top: 0;
    margin-bottom: 12px;
}
.sellOnlineProcess .detailsSection .itemWrapper .subTitleWrapper {
    display: flex;
}
.sellOnlineProcess .detailsSection .itemWrapper .subTitleWrapper span {
    display: flex;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: #205c8c;
    width: 50%;
    text-align: center;
    text-align: start;
    color: #979797;
}
.sellOnlineProcess .detailsSection .itemWrapper .valueWrapper {
    display: flex;
}
.sellOnlineProcess .detailsSection .itemWrapper .valueWrapper h3 {
    margin: 0;
    display: flex;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: #465166;
    width: 50%;
}
.sellOnlineProcess .detailsSection .itemWrapper .valueWrapper .yourValue {
    color: #ed6a00;
}
.sellOnlineProcess .detailsSection .itemWrapper .valueWrapper .evaluatorValue {
    color: #205c8c;
}
.sellOnlineProcess .detailsSection .itemWrapper .valueWrapper .greenValue {
    color: #09c262;
}
.sellOnlineProcess .detailsSection .itemWrapper .subDetailsWrapper {
    border-left: 1px solid #ed6a00;
}
.sellOnlineProcess .detailsSection .itemWrapper .subDetailsWrapper .subDetails {
    display: flex;
    margin-top: 15px;
    align-items: center;
    margin-left: 8px;
}
.sellOnlineProcess .detailsSection .itemWrapper .subDetailsWrapper .subDetails h3 {
    margin: 0;
    display: flex;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: #465166;
    width: 50%;
    margin-bottom: 12px;
}
.sellOnlineProcess .detailsSection .itemWrapper .subDetailsWrapper .subDetails .detailValue {
    text-align: center;
    color: #205c8c;
    width: 150px;
}
.sellOnlineProcess .ctaWrapper {
    margin: 12px 0;
    margin-bottom: 25px;
    justify-content: space-between;
}
.sellOnlineProcess .ctaWrapper button {
    margin-bottom: 12px;
    border-radius: 5px;
}
.sellOnlineProcess .ctaWrapper .gradient {
    background: linear-gradient(225deg, #f69b00 0%, #ed6a00 100%);
    box-shadow: 0px 5px 10px rgba(237, 108, 13, 0.3);
}
.sellOnlineProcess .clarificationText {
    font-weight: 400;
    background: rgba(0, 82, 204, 0.1);
    font-size: 12px;
    line-height: 18px;
    color: #465166;
    opacity: 0.7;
    position: relative;
    margin: 0;
    padding: 10px 26px 10px 44px;
    border-radius: 5px;
    margin-bottom: 27px;
}
.sellOnlineProcess .clarificationText::before {
    content: '';
    background-repeat: no-repeat;
    width: 18px;
    height: 20px;
    background-image: url('./images/Evaluator.svg');
    position: absolute;
    top: 18px;
    left: 15px;
    margin: auto;
}
.sellOnlineProcess .satisfiedText {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #465166;
    padding: 8px 30px;
    margin: 18px 0px;
    text-align: center;
    margin: 0 -20px;
    background: rgba(32, 92, 140, 0.06);
}
.sellOnlineProcess .errorMessage {
    color: #ec2806 d3;
    text-align: center;
    margin-top: 40px;
    border: 1px solid #ec2806 d3;
    padding: 25px;
    border-radius: 5px;
}
@media screen and (min-width: 600px) {
    .sellOnlineProcess {
        width: 600px;
        margin: auto;
        border: 1px solid #dfdddd;
   }
    .sellOnlineProcess .clarificationText::before {
        top: 10px;
   }
}
