.reInspection {
    padding: 0 20px;
}
.reInspection .subContent {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    margin: 0;
    padding: 8px 0 20px 0;
}
.reInspection .commentP {
    margin-bottom: 8px;
}
.reInspection table thead tr th {
    background-color: #ddd;
    font-size: 18px;
    font-weight: bold;
    color: #212121;
    height: 75px;
    text-align: left;
    width: 30%;
    padding: 0 0px 0 20px;
}
.reInspection table thead tr th p {
    margin: 0;
    padding: 4px 0;
}
.reInspection table thead tr th .buttonSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 50px;
}
.reInspection table tbody tr td {
    font-size: 16px;
    font-weight: bold;
    color: #212121;
    vertical-align: top;
    width: 30%;
}
.reInspection table tbody tr td .titles {
    color: #758491;
    font-weight: normal;
    margin: 0;
    padding-bottom: 6px;
}
.reInspection table tbody tr td img {
    border-radius: 4px;
    max-width: 100%;
}
.reInspection table tbody tr td .tableContent {
    display: flex;
}
.reInspection table tbody tr td .contentSection {
    max-width: 400px;
}
.reInspection table tbody tr td .condtion {
    font-size: 14px;
    font-weight: bold;
    color: #b45d15;
    padding-bottom: 5px;
}
.reInspection table tbody tr td .notAvailable {
    font-size: 14px;
    font-weight: bold;
    color: #e95f5f;
}
.reInspection table tbody tr td .imageWrapper {
    background-color: rgba(145, 144, 144, 0.53);
    width: 114px;
    height: 74px;
    border-radius: 4px;
    margin-left: 50px;
}
.reInspection table tbody tr td p {
    margin: 0;
}
.reInspection table tbody tr td .subTable {
    border-bottom: 1px solid #bfd7ea;
}
.reInspection table tbody tr td .subTable tr td {
    padding: 20px 0px 20px 20px !important;
}
.reInspection .textWrapper {
    background-color: #c1c1c1 b3;
    padding: 10px;
    font-size: 14px;
    min-height: 100px;
}
