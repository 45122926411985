.exteriorWrapper {
    padding: 0 20px;
    margin: 70px 0 0 0;
}
.exteriorWrapper .exteriorComment {
    display: flex;
    justify-content: flex-end;
    background-color: #edf2f7;
    padding: 0px 0 10px 0;
    margin-top: 10px;
}
.exteriorWrapper .exteriorComment .exteriorText {
    display: flex;
    margin-top: 16px;
}
.exteriorWrapper .exteriorComment .exteriorTextList {
    width: 39%;
}
.exteriorWrapper .exteriorComment .exteriorTextList p {
    margin: 0;
    padding-left: 10px;
}
.exteriorWrapper .exteriorComment .exteriorTextList ul {
    margin: 10px 0 0 20px;
    padding: 0;
}
.exteriorWrapper .exteriorComment .exteriorTextList ul li {
    list-style: none;
    display: flex;
    padding: 5px 0;
}
.exteriorWrapper .exteriorComment .exteriorTextList ul li p {
    padding-left: 10px;
    margin: 0;
}
.exteriorWrapper .exteriorMainWrapper {
    margin-top: 46px;
}
.exteriorWrapper .exteriorMainWrapper h3 {
    margin-bottom: 10px;
}
.exteriorWrapper .exteriorMainWrapper .imageWrapper {
    display: grid;
    grid-template-columns: 49% 49%;
    column-gap: 20px;
    row-gap: 20px;
    margin-bottom: 25px;
}
.exteriorWrapper .exteriorMainWrapper .imageWrapper .exteriorImage img {
    width: 100%;
}
.exteriorWrapper .exteriorMainWrapper .imageWrapper .exteriorImage :global .TransformComponent-module_content__TZU5O {
    height: 513px;
    width: 100%;
    position: relative;
    background-color: #c1c1c1 b3;
}
.exteriorWrapper .exteriorMainWrapper .imageWrapper .exteriorImage :global .TransformComponent-module_content__TZU5O img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}
