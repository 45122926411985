.customerDocumentPopup {
    min-width: 600px;
    min-height: 400px;
}
.customerDocumentPopup .heading {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    display: flex;
    flex-flow: column;
    align-items: center;
}
.info {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 400;
}
.infoHighlight {
    font-size: 14px;
    font-weight: 700;
}
.selectHash {
    z-index: 999;
}
.imageWrapper .carousel {
    flex: 1 1 60%;
    width: 60%;
    /* position: relative; */
}
.customerDocumentPopup .greenCtaButton {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
}
.customerDocumentPopup .successMsg {
    color: #168016;
} 

.imageWrapper{
    display: flex;
    justify-content: center;
    /* margin: 20px 0px; */
}

.carouselActions{
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
}

.carouselActionsCta{
    margin: 0px 10px;
    padding: 10px 45px;
    background-color: #014076;
    color: #fff;
    border: 1px;
    cursor: pointer;
    border-radius: 10px;
}

.carouselActionsDiscardCta{
    margin: 0px 5px;
    padding: 5px 45px;
    color: #014076;
    border: 1px solid #014076;
    background-color: #fff;
    cursor: pointer;
    border-radius: 10px;
}

.ctaBanner{
    display: flex;
    align-items: center;
    justify-content: center;
}