.mainListViewContainer {
    margin: 0 50px;
}
.mainListViewContainer .variantTopModle {
    margin-top: 100px;
}
.mainListViewContainer .variantTopModle ul {
    margin: 0;
    padding: 0;
    width: 70%;
}
.mainListViewContainer .variantTopModle ul li {
    list-style: none;
    border-bottom: 1px solid #d4d8e3;
    display: flex;
    align-items: center;
    margin: 5px 0 5px 0;
    padding: 0px 0 5px 0;
    min-height: 40px;
}
.mainListViewContainer .variantTopModle ul li p {
    margin: 0;
    padding: 0;
    width: 30%;
}
.mainListViewContainer .variantTopModle ul li .variantBox {
    display: flex;
}
.mainListViewContainer .variantTopModle ul li .variantBox .variant {
    margin-right: 30px;
}
.mainListViewContainer .variantTopModle ul li .errorMessage {
    font-size: 12px;
    color: #dd1818;
    margin-left: 10px;
}
.mainListViewContainer .addVariantBtn {
    position: absolute;
    top: 150px;
    right: 10%;
}
.mainListViewContainer .addVariantBtn button {
    padding: 10px 40px;
    width: 200px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid gray;
    color: #0059a3;
    font-weight: bold;
    box-shadow: 1px 1px #a19e9e;
}
.mainListViewContainer .submitBtn {
    position: absolute;
    top: 250px;
    right: 10%;
}
.mainListViewContainer .submitBtn button {
    padding: 10px 40px;
    width: 200px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #a19e9e;
    font-weight: bold;
    color: #0059a3;
    box-shadow: 1px 1px #a19e9e;
}
.mainListViewContainer .variantListWrapper {
    margin-top: 33px;
}
.mainListViewContainer .variantListWrapper .enableDisableBtn {
    text-align: right;
    margin-bottom: 30px;
}
.mainListViewContainer .variantListWrapper .enableDisableBtn button {
    padding: 10px 40px;
    width: 300px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid gray;
    color: #0059a3;
    font-weight: bold;
    box-shadow: 1px 1px #a19e9e;
}
.mainListViewContainer .variantListWrapper ul {
    margin: 0;
    padding: 10px;
    background-color: #edf6fa;
}
.mainListViewContainer .variantListWrapper ul li {
    list-style: none;
    font-size: 16px;
    border-radius: 10px;
    color: #000;
    display: flex;
    align-items: center;
    margin: 10px 0;
    border: 1px solid #d4d2d2;
    padding: 10px;
}
.mainListViewContainer .variantListWrapper ul li p {
    margin: 0;
    padding: 0;
    width: 150px;
}
.mainListViewContainer .variantListWrapper ul li .variantName {
    width: 200px;
    text-align: center;
}
.mainListViewContainer .variantListWrapper ul li .variantList {
    min-height: 52px;
    border-radius: 5px;
    border: solid 1px #d0dce7;
    background-color: #f3f3f3;
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    padding: 5px;
    margin-left: 20px;
}
.mainListViewContainer .variantListWrapper ul li .variantList span {
    font-size: 16px;
    color: #141414;
    height: 28px;
    border-radius: 14px;
    border: solid 1px #979797;
    background-color: #fff;
    padding: 0 15px;
    display: inline-flex;
    align-items: center;
    margin: 2px 0px 2px 10px;
}
.mainListViewContainer .variantListWrapper ul li .actionBtnWrapper {
    margin-left: 15px;
}
.mainListViewContainer .variantListWrapper ul li .actionBtnWrapper button {
    width: 100%;
    border: 1px solid #979797;
    border-radius: 25px;
    background-color: #fff;
    margin: 5px;
    padding: 5px;
    outline: none;
    cursor: pointer;
    font-weight: bold;
}
.mainListViewContainer .variantListWrapper .missingVariantsContainer .missingVariantsWrapper {
    border: 1px solid #979797;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
}
.mainListViewContainer .variantListWrapper .missingVariantsContainer .missingVariantsWrapper h3 {
    margin: 0;
}
.mainListViewContainer .variantListWrapper .missingVariantsContainer .missingVariantsWrapper p {
    color: #ec2222;
    font-weight: bold;
}
.mainListViewContainer .variantListWrapper .missingVariantsContainer .missingVariantsBtn {
    margin: 15px;
}
.mainListViewContainer .variantListWrapper .missingVariantsContainer .missingVariantsBtn button {
    padding: 10px 20px;
    width: 200px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #a19e9e;
    font-weight: bold;
    color: #0059a3;
    box-shadow: 1px 1px #a19e9e;
}
.mainListViewContainer .confirmContainer .variantName {
    font-weight: bold;
    font-size: 16px;
}
.mainListViewContainer .confirmContainer .confirmBtnWrapper button {
    width: 100%;
    border: 1px solid #979797;
    border-radius: 25px;
    background-color: #fff;
    margin: 5px;
    padding: 5px;
    outline: none;
    cursor: pointer;
    font-weight: bold;
}
.mainListViewContainer .confirmContainer .confirmBtnWrapper .deleteVariant {
    margin-top: 10px;
}
.parameterTable {
    width: 800px;
    border: 1px solid #dfe3ea;
    border-right: none;
    border-bottom: none;
}
.parameterTable tr th {
    height: 40px;
}
.parameterTable tr td, .parameterTable tr th {
    border-bottom: 1px solid #dfe3ea;
    padding: 4px 10px;
    width: 50%;
}
.parameterTable tr:nth-child(n+2) th, .parameterTable tr:first-child th, .parameterTable tr:nth-child(n+2) td, .parameterTable tr:first-child td {
    border-right: 1px solid #dfe3ea;
}
.errorMessage {
    font-size: 12px;
    color: #e71818;
}
.ctaWrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 20px 0 0 0;
}
.ctaWrapper button {
    width: auto;
    padding: 0 40px;
    background-color: transparent;
}
.confirmContainer {
    width: 600px;
}
.confirmContainer .variantName {
    font-weight: bold;
    font-size: 16px;
}
.confirmContainer .confirmBtnWrapper {
    display: flex;
    justify-content: center;
}
.confirmContainer .confirmBtnWrapper button {
    width: 200px;
    border: 1px solid #979797;
    border-radius: 25px;
    background-color: #fff;
    margin: 5px;
    padding: 10px;
    outline: none;
    cursor: pointer;
    font-weight: bold;
}
.confirmContainer .confirmBtnWrapper .deleteVariant {
    margin-top: 10px;
}
