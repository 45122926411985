.inspectionHeader {
    display: flex;
    min-height: 70px;
    background-color: #edf2f7;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
}
.inspectionList {
    padding: 0 20px;
    margin-top: 60px;
}
.inspectionList table {
    border-spacing: 0 8px;
    border-collapse: unset;
}
.inspectionList table thead tr th {
    font-size: 18px;
    font-weight: bold;
    color: #212121;
    height: 40px;
    text-align: left;
}
.inspectionList table thead tr th .count {
    color: #0059a3;
}
.inspectionList table tbody tr {
    cursor: pointer;
}
.inspectionList table tbody tr td {
    font-size: 16px;
    color: #141414;
    background-color: #edf2f7;
    height: 40px;
}
.inspectionList table tbody tr td p {
    margin: 0;
    padding: 0;
}
.categoryCell {
    display: flex;
}
.done {
    margin-left: 10px !important;
    border-radius: 3px;
    background-color: #E19E35;
    font-size: 12px;
    color: #fff;
    padding: 5px !important;
}
.inspectionList table tbody tr td .assign {
    border-radius: 3px;
    border: solid 1px #fa6a00;
    background-color: #e87211;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    padding: 4px 10px;
}
.inspectionList table tbody tr td .qcAudit {
    background-color: #0059a3;
    color: #fff;
    text-align: center;
    font-size: 14px;
    display: inline-table;
    padding: 0 10px;
}
.inspectionList table tbody tr td .qcAudit span {
    display: block;
}
.inspectionList table tbody tr .riAfterRefurb {
    background-color: #0059a3;
    color: #fff;
    padding: 5px 15px;
    font-weight: bold;
    width: 5%;
}
.noAppointment {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
}
