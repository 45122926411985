.fixedHeader {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9999;
}
.reviewWrapper {
    margin-top: 145px;
}
.reviewWrapper .carouselTextWrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    margin-top: 70px;
}
.reviewWrapper .carouselTextWrapper .carousel {
    flex: 1 1 60%;
    width: 60%;
}
.infoSection {
    margin-top: 32px;
}
.misMatchSection {
    margin-top: 14px;
    border-top: 1px solid #d7d7d7;
}
.misMatchSection ul {
    margin: 0;
    padding: 0;
}
.misMatchSection ul li {
    list-style: none;
    align-items: center;
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid #e3e3e3;
}
.misMatchSection ul li p {
    margin: 0;
    padding: 0px;
    flex: 1 2 50%;
}
.misMatchSection ul li .carsinfo {
    flex: 1 1 50%;
}
.misMatch {
    margin-top: 14px;
}
.carouselWrapper {
    display: flex;
    flex: 1 1 60%;
    width: 60%;
}
.keyCarousel {
    margin-left: 20px;
}
.commentsCarDetails {
    flex: 1 2 40%;
    margin-left: 20px;
}
.exteriorWrapper {
    padding: 0 20px;
    margin: 70px 0 0 0;
}
.exteriorWrapper .exteriorComment {
    display: flex;
    justify-content: flex-end;
}
.exteriorWrapper .exteriorComment .exteriorText {
    display: flex;
    margin-top: 16px;
}
.exteriorWrapper .exteriorComment .exteriorTextList {
    width: 39%;
}
.exteriorWrapper .exteriorComment .exteriorTextList ul {
    margin: 10px 0 0 20px;
    padding: 0;
}
.exteriorWrapper .exteriorComment .exteriorTextList ul li {
    list-style: none;
    display: flex;
    padding: 5px 0;
}
.exteriorWrapper .exteriorComment .exteriorTextList ul li p {
    padding-left: 10px;
    margin: 0;
}
.exteriorWrapper .exteriorMainWrapper {
    margin-top: 46px;
}
.exteriorWrapper .exteriorMainWrapper .imageWrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 20px;
    margin-bottom: 25px;
}
.exteriorWrapper .exteriorMainWrapper .imageWrapper .exteriorImage img {
    max-width: 100%;
}
.tyresWrapper {
    margin-top: 70px;
}
.tyresList {
    display: flex;
    margin-top: 24px;
}
.tyresList .tyresListItem {
    width: 260px;
    margin-right: 20px;
}
.tyresList .tyresListItem:last-child {
    margin-right: 0;
}
.tyresList .tyresListItem img {
    max-width: 100%;
}
.reInspection {
    margin: 70px 0 60px 0;
}
