.inspectionReviewCarInfo .inspectionInfo {
    font-size: 16px;
    font-weight: 600;
    color: #646464;
    padding-right: 10px;
    margin: 0;
}
.inspectionReviewCarInfo .inspectionInfo span {
    padding-right: 8px;
}
.inspectionReviewCarInfo .inspectionInfo .variantBold {
    font-weight: bold;
    color: #000;
    font-size: 17px;
}
.inspectionReviewCarInfo .inspectionText {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.inspectionReviewCarInfo .inspectionText .scrap {
    font-size: 16px;
    font-weight: 600;
    color: #646464;
    padding-right: 10px;
    margin: 0;
}
.inspectionReviewCarInfo .inspectionText .infoChange {
    display: flex;
    margin-top: 10px;
}
.inspectionReviewCarInfo .inspectionText .infoChange .inspectionInfoWrapper {
    display: flex;
    padding-bottom: 15px;
    gap: 10px;
}
.makeModelVariantYear {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.makeModelVariantYear .filters {
    display: flex;
}
.makeModelVariantYear .filters .boxSelect {
    min-width: 100px;
    margin-right: 10px;
}
.makeModelVariantYear .filters .boxSelect :global .react-select-container {
    min-width: 130px !important;
}
.change {
    font-size: 12px;
    font-weight: 500;
    color: #969696;
    margin: 0px 0 0 0;
    padding-left: 20px;
    position: relative;
}
.change span {
    color: #0059a3;
}
.change::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('../images/editIcon.svg');
    background-repeat: no-repeat;
    height: 14px;
    width: 14px;
}
