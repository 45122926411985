.error{
  color: red;
  font-size: 12px;
  font-weight: normal;
}
:global .react-select-container {
  font-size: 14px;
  min-width: 170px !important;
}

:global .variantWidth {
  width: 300px !important;
}
/* .error .react-select__control{
  border-color: red;
} */
:global .react-select__menu {
  z-index: 999 !important;
 }
 .validatorInput :global .react-select__control {
    border: 1px solid red;
 }
 .validatorInput :global .react-select__control:hover {
  border: 1px solid red;
 }
 .validatorInput :global .css-1wa3eu0-placeholder {
   color: red !important;
 }
 :global .css-1fhf3k1-control {background-color: transparent !important;}
 :global .css-1uccc91-singleValue {font-size: 14px !important;}