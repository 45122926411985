.change {
    font-size: 12px;
    color: #141414;
    position: relative;
    margin: 5px 0 0 5px;
    padding-left: 20px;
}
.change span {
    color: #0059a3;
}
.change::before {
    content: '';
    background-image: url(../images/editIcon.svg);
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    width: 14px;
    height: 14px;
}
.change .error {
    display: block;
    color: #ea5f5f;
}
