.search {
    border: 1px solid #cecece;
    border-radius: 5px;
    font-size: 16px;
    color: #787878;
    display: flex;
    min-width: 200px;
    padding: 5px 0px 5px 10px;
}
.search input {
    background: transparent;
    border: none;
    width: 100%;
}
.search input:focus {
    border: none;
    outline: none;
}
.search button {
    background: transparent;
    border: none;
    outline: none;
}
