.container {
  padding: 0 20px;
}
.subheader {
  display: flex;
  min-height: 70px;
  background-color: #edf2f7;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}
.addBtnContainer {
  margin: 0 20px 0 auto;
  display: flex;
  align-items: center;
}
.errorText {
  color: red;
  font-size: 12px;
  font-weight: normal;
}
.errorInput {
  border: 1px solid red;
}
.errorInput ::placeholder {
  color: red;
}
