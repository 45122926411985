.gsRefurbishment {
    margin-bottom: 20px;
}
.fixedHeader {
    position: fixed;
    top: 0;
    right: 0;
    left: 0px;
    z-index: 1;
}
.fixedHeader .gsRefurbishmentHeader {
    background-color: #0059a3;
    height: 55px;
    display: flex;
    align-items: center;
}
.fixedHeader .gsRefurbishmentHeader .goBack {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    position: relative;
    padding: 0 0 0 60px;
    cursor: pointer;
}
.fixedHeader .gsRefurbishmentHeader .goBack::before {
    content: '';
    background-image: url('../../components/images/back-button.svg');
    background-repeat: no-repeat;
    width: 18px;
    height: 20px;
    position: absolute;
    top: 0;
    left: 20px;
    bottom: 0;
    margin: auto;
}
.fixedHeader .gsRefurbishmentHeader p {
    margin: 0;
    padding: 0 20px 0 0;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
}
.inspectionReviewInfo {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    background-color: #edf2f7;
    padding: 0px 20px 0px 20px;
    min-height: 64px;
    display: flex;
    align-items: center;
}
.inspectionReviewInfo .inspectionInfoWrapper {
    display: flex;
}
.inspectionReviewInfo .inspectionInfoWrapper .inspectionInfo {
    font-size: 16px;
    font-weight: 600;
    color: #646464;
    padding-right: 10px;
    margin: 0;
}
.inspectionReviewInfo .inspectionInfoWrapper .inspectionInfo span {
    padding-right: 8px;
}
.inspectionReviewInfo .inspectionInfoWrapper .inspectionInfo .variantBold {
    font-weight: bold;
    color: #000;
    font-size: 17px;
}
.inspectionReviewInfo .inspectionInfoWrapper .scrap {
    font-size: 16px;
    font-weight: 600;
    color: #646464;
    padding-right: 10px;
    margin: 0;
}
.marginWrapper {
    margin-top: 140px;
}
.marginWrapper .refurbishmentMainWrapper {
    border: 1px solid #d0dce7;
    border-radius: 10px;
    margin: 30px 20px 20px 20px;
}
.marginWrapper .refurbishmentMainWrapper .headingText {
    font-size: 16px;
    font-weight: 600;
    color: #646464;
    margin: 0;
    padding: 10px 20px;
    border-bottom: 1px solid #d0dce7;
}
.marginWrapper .refurbishmentMainWrapper .headingText span {
    color: #000;
}
.marginWrapper .refurbishmentMainWrapper .beforeAfterWrapper {
    display: flex;
    border-bottom: 1px solid #d0dce7;
    padding: 0px 20px;
}
.marginWrapper .refurbishmentMainWrapper .beforeAfterWrapper .beforeWrapper, .marginWrapper .refurbishmentMainWrapper .beforeAfterWrapper .afterWrapper {
    width: 49%;
    position: relative;
}
.marginWrapper .refurbishmentMainWrapper .beforeAfterWrapper .beforeWrapper h3, .marginWrapper .refurbishmentMainWrapper .beforeAfterWrapper .afterWrapper h3 {
    font-size: 16px;
    font-weight: 600;
    color: #646464;
    margin: 0;
    padding: 10px 0;
}
.marginWrapper .refurbishmentMainWrapper .beforeAfterWrapper .beforeWrapper .estimatedCost, .marginWrapper .refurbishmentMainWrapper .beforeAfterWrapper .afterWrapper .estimatedCost {
    border: 1px solid #d0dce7;
    border-radius: 10px;
    padding: 8px 15px;
    margin: 10px 0;
    width: 75%;
}
.marginWrapper .refurbishmentMainWrapper .beforeAfterWrapper .beforeWrapper .estimatedCost h4, .marginWrapper .refurbishmentMainWrapper .beforeAfterWrapper .afterWrapper .estimatedCost h4 {
    margin: 0;
}
.marginWrapper .refurbishmentMainWrapper .beforeAfterWrapper .beforeWrapper {
    border-right: 1px solid #d0dce7;
    padding-right: 20px;
}
.marginWrapper .refurbishmentMainWrapper .beforeAfterWrapper .afterWrapper {
    padding-left: 20px;
}
.marginWrapper .refurbishmentMainWrapper .beforeAfterWrapper .imageSection {
    background-color: #d3d3d3;
    min-height: 500px;
}
.marginWrapper .refurbishmentMainWrapper .absolutePosition {
    position: absolute;
    bottom: 0;
}
.marginWrapper .refurbishmentMainWrapper .prevComments {
    border: 1px solid #d0dce7;
    border-radius: 10px;
    padding: 8px 15px;
    margin: 10px 0;
    width: 75%;
    position: absolute;
    bottom: 0;
}
.marginWrapper .refurbishmentMainWrapper .prevComments h4 {
    font-weight: bold;
    color: #000;
    font-size: 17px;
    margin: 0;
    padding-bottom: 4px;
}
.marginWrapper .refurbishmentMainWrapper .prevComments p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    color: #646464;
}
.marginWrapper .refurbishmentMainWrapper .afterComments {
    border: 1px solid #d0dce7;
    border-radius: 10px;
    padding: 8px 15px;
    margin: 10px 0;
    width: 75%;
}
.marginWrapper .refurbishmentMainWrapper .afterComments h4 {
    font-weight: bold;
    color: #000;
    font-size: 17px;
    margin: 0;
    padding-bottom: 4px;
}
.marginWrapper .refurbishmentMainWrapper .afterComments p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    color: #646464;
}
.marginWrapper .refurbishmentMainWrapper .commentsBefore {
    display: flex;
}
.marginWrapper .refurbishmentMainWrapper .commentsBefore .beforeAfterWrapper {
    width: 100%;
    border-bottom: none;
}
.marginWrapper .refurbishmentMainWrapper .commentsBefore .beforeAfterWrapper .beforeWrapper {
    border-right: none;
}
.marginWrapper .refurbishmentMainWrapper .commentsBefore .beforeAfterWrapper .afterWrapper .afterComments {
    width: 98%;
}
.marginWrapper .refurbishmentMainWrapper .commentsBefore .beforeAfterWrapper .afterWrapper .afterComments input {
    width: 80%;
}
.marginWrapper .refurbishmentMainWrapper .commentsBefore .prevComments, .marginWrapper .refurbishmentMainWrapper .commentsBefore .afterComments {
    width: 94%;
    border: none;
    padding: 8px 0;
}
.marginWrapper .refurbishmentMainWrapper .commentsBefore .prevComments h4, .marginWrapper .refurbishmentMainWrapper .commentsBefore .afterComments h4 {
    font-weight: bold;
    color: #000;
    font-size: 17px;
    margin: 0;
    padding-bottom: 4px;
}
.marginWrapper .refurbishmentMainWrapper .commentsBefore .prevComments p, .marginWrapper .refurbishmentMainWrapper .commentsBefore .afterComments p, .marginWrapper .refurbishmentMainWrapper .commentsBefore .prevComments input, .marginWrapper .refurbishmentMainWrapper .commentsBefore .afterComments input {
    border: 1px solid #d0dce7;
    border-radius: 10px;
    height: 60px;
    display: flex;
    align-items: center;
    margin: 5px 0 0 0;
    padding: 0 10px;
    width: 97%;
}
.marginWrapper .refurbishmentMainWrapper .commentsBefore .prevComments p:focus, .marginWrapper .refurbishmentMainWrapper .commentsBefore .afterComments p:focus, .marginWrapper .refurbishmentMainWrapper .commentsBefore .prevComments input:focus, .marginWrapper .refurbishmentMainWrapper .commentsBefore .afterComments input:focus {
    outline: none;
}
.marginWrapper .refurbishmentMainWrapper .commentsBefore .prevComments .rejectButton, .marginWrapper .refurbishmentMainWrapper .commentsBefore .afterComments .rejectButton {
    border-radius: 30px;
    height: 36px;
    padding: 0 20px;
    border: none;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    background-color: #e87211;
    transition: all 0.2s;
    margin-left: 20px;
}
.marginWrapper .refurbishmentMainWrapper .commentsBefore .prevComments .rejectButton:focus, .marginWrapper .refurbishmentMainWrapper .commentsBefore .afterComments .rejectButton:focus {
    outline: none;
}
.marginWrapper .refurbishmentMainWrapper .commentsBefore .prevComments .selectRejectButton, .marginWrapper .refurbishmentMainWrapper .commentsBefore .afterComments .selectRejectButton {
    border-radius: 30px;
    height: 36px;
    padding: 0 20px;
    border: none;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    background-color: #dd1818;
    transition: all 0.2s;
    margin-left: 20px;
}
.marginWrapper .refurbishmentMainWrapper .commentsBefore .prevComments .selectRejectButton:focus, .marginWrapper .refurbishmentMainWrapper .commentsBefore .afterComments .selectRejectButton:focus {
    outline: none;
}
.marginWrapper .refurbishmentMainWrapper .commentsBefore .prevComments .actionWrapper, .marginWrapper .refurbishmentMainWrapper .commentsBefore .afterComments .actionWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.marginWrapper .refurbishmentMainWrapper .commentsBefore .prevComments .actionWrapper p, .marginWrapper .refurbishmentMainWrapper .commentsBefore .afterComments .actionWrapper p {
    width: 81%;
}
.totalCostWrapper {
    display: flex;
    border: 1px solid #d0dce7;
    border-radius: 10px;
    margin: 30px 20px 20px 20px;
    justify-content: space-between;
    padding: 10px 20px;
}
.totalCostWrapper .totalCost {
    border: 1px solid #d0dce7;
    border-radius: 10px;
    padding: 10px;
    margin: 10px 0;
    width: 40%;
}
.totalCostWrapper .totalCost h4 {
    margin: 0;
}
.ctaWrapper {
    display: flex;
    justify-content: flex-end;
    margin: 0 20px;
}
.videosWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 100px;
}
.videosWrapper .videoSection {
    width: 330px;
    margin-right: 30px;
    margin-top: 30px;
}
.videosWrapper .videoSection .videoText {
    background-color: #e0ecf6;
    padding: 15px;
    margin: 0;
}
.loaderWrapper {
    position: fixed;
    top: 50%;
    right: 50%;
}
.refurbishImageTitle {
    background-color: #e0ecf6;
    margin: 0;
    padding: 15px;
    color: #e0ecf6;
}
.errorMessage {
    color: #f00;
    display: flex;
    justify-content: center;
}
