.header {
    background-color: #0059a3;
    padding: 0 20px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header .logoSection {
    display: flex;
    align-items: center;
}

.header .logoSection .userName {
    margin: 0;
    padding-left: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}

.header .versionSection,
.header .logout,
.header a {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    align-items: center;
}

.header .versionSection a {
    cursor: pointer;
}

.pdfUrl {
    background-color: #f5f5f5 2e;
    padding: 8px 16px;
    border-radius: 16px;
}

.performanceWrapper {
    margin-right: 20px;
    position: relative;
    padding: 18px 0;
    transition: all 0.2s ease 0s;
}

.performanceWrapper .performanceText {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    align-items: center;
    margin: 0;
    padding-right: 20px;
    position: relative;
    transition: all 0.5s ease 0s;
}

.performanceWrapper .performanceText::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-image: url('../images/comArrow.svg');
    background-repeat: no-repeat;
    width: 21px;
    height: 8px;
    transform: rotate(180deg);
}

.performanceWrapper .performanceTextWrapper {
    position: absolute;
    right: -26px;
    top: 53px;
    z-index: 1;
    background-color: #0059a3;
    border-radius: 10px;
    border: 1px solid #fff;
    width: 150px;
    display: none;
}

.performanceWrapper .performanceTextWrapper ul {
    margin: 0;
    padding: 0px;
    text-align: center;
}

.performanceWrapper .performanceTextWrapper ul li {
    display: flex;
    justify-content: center;
    list-style: none;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    border-bottom: 1px solid #fff;
    padding: 10px 12px;
    line-height: 20px;
}

.performanceWrapper .performanceTextWrapper ul li:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.performanceWrapper .performanceTextWrapper ul li:last-child {
    border-bottom: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.performanceWrapper .performanceTextWrapper ul li:hover {
    background-color: #004374;
}

.performanceWrapper:hover .performanceText::after {
    content: '';
    transform: rotate(0deg);
    right: -10px;
}

.performanceWrapper:hover .performanceTextWrapper {
    display: block;
}

.headerBtn {
    background: #2F60A2;
    border: 1px solid #FFFFFF;
    border-radius: 12px;
    padding: 6px 20px;
    gap: 10px;
    font-family: 'Roboto';
    font-weight: 800;
    font-size: 15px;
    line-height: 22px;
    color: #FFFFFF;
    margin-left: 15px;

}