.carVariantWidgetPanel {
    width: 26%;
    position: fixed;
    top: 75px;
    right: 30px;
    bottom: 0px;
}
.carVariantWidgetPanel .addParameter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid #d4d8e3;
    padding-bottom: 20px;
}
.carVariantWidgetPanel .addParameter button {
    border: 1px solid #0059a3;
    height: 42px;
    border-radius: 4px;
    font-size: 18px;
    font-weight: bold;
    color: #888;
    cursor: pointer;
    padding: 0 15px;
    background: transparent;
}
.carVariantWidgetPanel .addParameter button:focus {
    outline: none;
    border: 1px solid #0059a3;
}
.carVariantWidgetPanel .widgetWrapper {
    position: fixed;
    top: 155px;
    right: 30px;
    bottom: 0px;
    width: 26%;
    height: auto;
    overflow: auto;
}
.carVariantWidgetPanel .widgetWrapper ul {
    margin: 0;
    padding: 0;
}
.carVariantWidgetPanel .widgetWrapper ul li {
    cursor: pointer;
    list-style: none;
    font-size: 18px;
    font-weight: bold;
    color: #888;
    min-height: 46px;
    border-radius: 6px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #ddd;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 0 10px 0 40px;
    margin-bottom: 12px;
    position: relative;
}
.carVariantWidgetPanel .widgetWrapper ul li::before {
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    bottom: 0;
    margin: auto;
    background-image: url('../../components/images/widgetDots.svg');
    background-repeat: no-repeat;
    width: 12px;
    height: 13px;
}
.carVariantWidgetPanel .widgetWrapper ul li .nameWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
