.tooltipWrapper {
    position: relative;
    margin-right: 10px;
    cursor: pointer;
}
.tooltipWrapper:hover .tooltip {
    display: block;
}
.tooltipWrapper .tooltip {
    background-color: #0059a3;
    border-radius: 3px;
    min-height: 50px;
    width: 230px;
    padding: 8px 10px;
    font-size: 12px;
    color: #fff;
    position: absolute;
    top: 37px;
    z-index: 99;
    display: none;
}
.tooltipWrapper .tooltip::before {
    content: '';
    position: absolute;
    top: -10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #0059a3;
}
.tooltipWrapper .tooltip table tr td {
    width: 50%;
    padding: 2px 0;
}
