.variantsPopup {
    min-width: 780px;
    min-height: 300px;
}
.variantsPopup .text {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin: 0;
    padding: 0;
}
.variantsPopup .variants {
    margin-top: 28px;
}
.variantsPopup .variants ul {
    margin: 0;
    padding: 0;
}
.variantsPopup .variants ul li {
    list-style: none;
    font-size: 16px;
    color: #141414;
    margin-bottom: 20px;
}
.variantsPopup .variants ul li span {
    font-weight: bold;
}
.variantsPopup .variants ul .firstLi {
    display: flex;
    justify-content: space-between;
    width: 70%;
}
.variantsPopup .variantListWrapper {
    margin-top: 33px;
}
.variantsPopup .variantListWrapper ul {
    margin: 0;
    padding: 0;
}
.variantsPopup .variantListWrapper ul li {
    list-style: none;
    font-size: 16px;
    font-weight: 600;
    color: #000;
    display: flex;
    align-items: center;
    margin: 10px 0;
}
.variantsPopup .variantListWrapper ul li p {
    margin: 0;
    padding: 0;
    width: 150px;
}
.variantsPopup .variantListWrapper ul li .variantList {
    min-height: 52px;
    border-radius: 5px;
    border: solid 1px #d0dce7;
    background-color: #f3f3f3;
    display: flex;
    align-items: center;
    width: 600px;
    flex-wrap: wrap;
    padding: 5px;
    margin-left: 40px;
}
.variantsPopup .variantListWrapper ul li .variantList span {
    font-size: 16px;
    color: #141414;
    height: 28px;
    border-radius: 14px;
    border: solid 1px #979797;
    background-color: #fff;
    padding: 0 15px;
    display: inline-flex;
    align-items: center;
    margin: 2px 0px 2px 10px;
}
.variantsPopup .ctaWrappr {
    margin-top: 50px;
    display: flex;
}
.variantsPopup .ctaWrappr .goBackCta, .variantsPopup .ctaWrappr .greenCtaButton {
    width: 100%;
}
.variantsPopup .ctaWrappr .goBackCta {
    margin-right: 10px;
}
.variantsPopup .ctaWrappr .greenCtaButton {
    margin-left: 10px;
}
.variantsPopup .error {
    color: #ca1111;
}
.variantsPopup .successText {
    color: #159b15;
}
