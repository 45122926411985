.carouselContent {
    flex: 1 2 40%;
    margin-left: 20px;
    height: 550px;
    overflow: auto;
    padding-bottom: 60px;
}
.carouselContent .text {
    font-size: 12px;
    font-weight: bold;
    color: #a6a6a6;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}
.carouselContent .text .checkbox {
    margin: 0 5px;
}
.carouselContent .text .info {
    padding: 0 5px;
    display: flex;
}
