.imageStrip, .imageStripGrey {
    background-color: #e0ecf6;
    min-height: 50px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    justify-content: space-between;
    flex: 1;
}
.imageStrip .imageDetails, .imageStripGrey .imageDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
}
.imageStrip .imageDetails .checkboxWrapper, .imageStripGrey .imageDetails .checkboxWrapper {
    display: flex;
}
.imageStrip .imageDetails .rejected, .imageStripGrey .imageDetails .rejected {
    color: #ea5f5f;
    font-size: 14px;
    padding-left: 10px;
}
.imageStrip .stripRight, .imageStripGrey .stripRight {
    font-size: 16px;
    font-weight: bold;
    color: #000;
}
.imageStrip .text, .imageStripGrey .text {
    font-size: 14px;
    color: #000;
    padding-left: 10px;
    margin: 0;
    text-transform: capitalize;
}
.imageStripGrey {
    background-color: #f5f5f5;
}
