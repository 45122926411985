.imageEditor {
    margin: 10px;
    height: 600px;
    overflow: scroll;
}
.imageEditor :global .tui-image-editor-submenu-style {
    background-color: #c1c1c1;
}
.imageEditor :global .tui-image-editor-container .tui-image-editor-main {
    top: 0px !important;
}
.imageEditor :global .tui-image-editor-container .tui-image-editor-controls {
    position: fixed;
    bottom: 48px;
    z-index: 8;
    left: 6%;
    right: 0;
    width: 88%;
}
.applyButton {
    text-align: center;
    height: 15px;
    margin-top: 10px;
    position: absolute;
    bottom: 85px;
    right: 70px;
    z-index: 9;
}
