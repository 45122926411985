.checkboxHolder{
    display: inline-block;
    height: 20px;
}
.checkboxHolder input[type="checkbox"] {
    display: none
}
.checkboxHolder label {
    cursor: pointer;
    position: relative;
    
}
.checkboxHolder input[type="checkbox"] + label:before,
.checkboxHolder input[type="checkbox"]:checked + label:before {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    position: relative;
    padding: 0;
    content: "";
    display: inline-block;
    top: 0px;
}

.checkboxHolder input[type="checkbox"] + label:before {
    background-image: url('../../images/blank_check.svg');
    background-repeat: no-repeat;
    border-color: transparent;
    background-size: 20px;
}
.checkboxHolder input[type="checkbox"]:checked + label:before {
    background-image: url('../../images/checkbox.svg');
    background-repeat: no-repeat;
    border-color: transparent;
    background-size: 20px;
}
.checkboxHolder input[type="checkbox"]:focus + label::before {
    outline: transparent;
}