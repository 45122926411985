.errorList {
    padding: 0 20px;
    margin-top: 70px;
}
.errorList table {
    text-align: left;
}
.errorList table thead tr th {
    background-color: #ececec;
    font-size: 18px;
    font-weight: bold;
    color: #212121;
    height: 50px;
    padding: 0 30px;
}
.errorList table tbody tr td {
    height: 50px;
    padding: 0 30px;
    border-bottom: 1px solid #e1e1e1;
}
.errorList table tbody tr td span {
    color: #e95f5f;
    font-size: 12px;
}
.errorList table tbody tr td img {
    background-color: #ececec;
}
