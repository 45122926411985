.taleData {
    width: 1024px;
    margin: auto;
    -webkit-print-color-adjust: exact;
}
.taleData tr td {
    width: 100%;
}
.taleData .cjRemark {
    margin-top: 64px;
}
.taleData .cjRemark h3 {
    color: #0062cc;
    font-weight: 400;
    font-size: 20px;
}
.taleData .cjRemark div {
    border: 1px solid #979595;
    border-radius: 5px;
    padding: 20px;
    line-height: 24px;
    font-size: 12px;
}
.taleData .cjRemark .listRow {
    line-height: 24px;
    font-size: 12px;
    display: block;
}
.taleData .tableTitle {
    background-color: #0059a3;
    width: 100%;
}
.taleData .tableTitle tr td {
    width: 33%;
    font-size: 12px;
    height: 50px;
    padding: 15px;
}
.taleData .tableTitle tr td:last-child {
    text-align: right;
}
.taleData .tableTitle tr td:nth-child(2) {
    text-align: center;
}
.taleData .tableTitle tr td p {
    color: #fff;
    margin: 0;
    padding: 0;
}
.taleData .title {
    width: 100%;
    margin-top: 10px;
}
.taleData .title tr td {
    font-size: 20px;
    padding: 10px 0;
    color: #0062cc;
    font-weight: 400;
}
.taleData .data1 {
    width: 100%;
    border: 1px solid #dee2e6;
}
.taleData .data1 tr:nth-child(odd) {
    background-color: #eaeaea;
}
.taleData .data1 tr td {
    height: 34px;
    line-height: 18px;
    padding: 0 15px;
    font-size: 12px;
    width: 25%;
    border-right: 1px solid #dee2e6;
}
.taleData .data1 tr td:nth-child(2) {
    font-weight: bold;
}
.taleData .data1 tr td:last-child {
    border-right: none;
    font-weight: bold;
}
.taleData .imageTable {
    width: 100%;
    border: 1px solid #dee2e6;
}
.taleData .imageTable tr td {
    width: 50%;
    padding: 15px;
    text-align: center;
}
.taleData .imageTable tr td:nth-child(1) {
    border-right: 1px solid #dee2e6;
}
.taleData .imageTable tr td.imageHeading {
    height: 34px;
    padding: 0 10px;
    font-size: 12px;
    text-align: left;
    background-color: #eaeaea;
    color: #000;
    font-weight: 500;
}
.taleData .daynamicList {
    width: 1024px;
    margin: 20px auto;
}
.taleData .daynamicList .subDataList {
    display: flex;
    flex-wrap: wrap;
}
.taleData .daynamicList .subDataList .dataWrapper {
    width: 49.5%;
    border: 1px solid #dee2e6;
    margin-bottom: 20px;
}
.taleData .daynamicList .subDataList .dataWrapper .titleText {
    padding: 0 15px;
    font-size: 12px;
    line-height: 18px;
    background-color: #eaeaea;
    height: 34px;
    display: flex;
    align-items: center;
    margin: 0;
}
.taleData .daynamicList .subDataList .dataWrapper .imgBox {
    width: 510px;
    height: 350px;
    position: relative;
}
.taleData .daynamicList .subDataList .dataWrapper .imgBox img {
    max-width: 90%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-height: 90%;
}
.taleData .daynamicList .subDataList .dataWrapper:nth-child(even) .imgBox {
    border-right: none;
}
