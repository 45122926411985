.slider {
  width: 97%;
  background-color: #f5f5f5;
  padding: 30px 10px;
  margin: 20px auto;
}
.slider :global .slick-prev {
  left: 30px !important;
}
.slider :global .slick-next {
  right: 60px !important;
}
.slider .carsCard {
  width: 90% !important;
  margin: 30px 12px 0;
  background-color: #fff;
  position: relative;
}
.slider :global .slick-track {
  display: flex;
}
.slider :global .slick-list {
  width: 85%;
  margin: auto;
}
.slider :global .slick-prev:before, .slider :global .slick-next:before {
  color: #000 !important;
  font-size: 40px !important;
}
.slider .carsCardImage {
  height: 199px;
  width: 100%;
  position: relative;
  background-color: #c1c1c1 b3;
}
.slider .carsCardImage img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
}
.carsCardImage :global .video-react-controls-enabled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.carsCardImageqcRequired {
  border: 6px solid #e87211;
  border-radius: 8px;
  padding-bottom: 40px;
}
.carsCardImageqcRequired .requiredText {
  display: block;
  background-color: #e87211;
  color: #fff;
  font-size: 14px;
  width: 100px;
  height: 40px;
  margin: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  border-top-left-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
 .requiredText {
  display: none;
}
 .text {
  margin: 0;
  padding: 7px 20px;
  font-size: 14px;
  border-bottom: 1px solid #e0e0e0;
}
.zoomIcon {
  position: absolute;
  left: 10px;
  top: 162px;
  z-index: 9;
  background-color: #fff;
  width: 26px;
  height: 26px;
}
.zoomIcon img {
  width: 80%;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.gridContainer {
  padding: 20px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
}

.carsCard {
  background: #fff;
  border: 1px solid #e0e0e0;
  position: relative;
}

.carsCardImage {
  height: 199px;
  padding: 10px;
  position: relative;
  background-color: #c1c1c1 b3;
}

.carsCardImage img, .carsCardImage video {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
}

.carsCardImageqcRequired {
  border-color: red;
}

.text {
  margin-left: 10px;
}

/* Adjustments for responsiveness */
@media (max-width: 1200px) {
  .grid {
      grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 768px) {
  .grid {
      grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .grid {
      grid-template-columns: 1fr;
  }
}
