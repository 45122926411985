.headerReinspection {
    background-color: #0059a3;
    height: 56px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}
.headerReinspection .inspectionId {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
}
.carName {
    background-color: #edf2f7;
    display: flex;
    align-items: center;
    height: 52px;
    margin: 56px 0 10px 0;
    padding: 0 20px;
}
.carName p {
    margin: 0;
    padding-right: 5px;
    font-size: 16px;
    font-weight: 600;
    color: #000;
}
