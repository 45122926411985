.inspectionHeader {
    display: flex;
    min-height: 70px;
    background-color: #edf2f7;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
}
.inspectionHeader .search {
    border: 1px solid #cecece;
    border-radius: 5px;
    font-size: 16px;
    color: #787878;
    display: flex;
    min-width: 200px;
    padding: 5px 0px 5px 10px;
}
.inspectionHeader .search input {
    background: transparent;
    border: none;
    width: 100%;
}
.inspectionHeader .search input:focus {
    border: none;
    outline: none;
}
.inspectionHeader .search button {
    background: transparent;
    border: none;
    outline: none;
}
.inspectionList {
    padding: 0 20px;
    margin-top: 60px;
}
.inspectionList table {
    border-spacing: 0 8px;
    border-collapse: unset;
}
.inspectionList table thead tr th {
    font-size: 18px;
    font-weight: bold;
    color: #212121;
    height: 40px;
    text-align: left;
}
.inspectionList table thead tr th .count {
    color: #0059a3;
}
.inspectionList table tbody tr {
    cursor: pointer;
}
.inspectionList table tbody tr td {
    font-size: 16px;
    color: #141414;
    background-color: #edf2f7;
    height: 40px;
}
.inspectionList table tbody tr td p {
    margin: 0;
    padding: 0;
}
.inspectionList table tbody tr td .assign {
    border-radius: 3px;
    border: solid 1px #fa6a00;
    background-color: #e87211;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    padding: 4px 10px;
}
.noAppointment {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
}
