.backSearchWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: #edf2f7;
}
.backSearchWrapper .search {
    border: 1px solid #cecece;
    border-radius: 5px;
    font-size: 16px;
    color: #787878;
    display: flex;
    min-width: 200px;
    padding: 5px 0px 5px 10px;
}
.backSearchWrapper .search input {
    background: transparent;
    border: none;
    width: 100%;
}
.backSearchWrapper .search input:focus {
    border: none;
    outline: none;
}
.backSearchWrapper .search button {
    background: transparent;
    border: none;
    outline: none;
}
.inspectionVersion .dataTable {
    padding: 30px 20px 0;
}
.inspectionVersion .dataTable table {
    margin: auto;
    border-collapse: collapse;
    overflow-x: auto;
    display: block;
    width: fit-content;
    max-width: 100%;
}
.inspectionVersion .dataTable table thead th {
    text-align: left;
    padding: 0 15px 10px 15px;
    padding-bottom: 15px;
}
.inspectionVersion .dataTable table tbody tr td {
    font-size: 14px;
    padding: 0 15px 0px 15px;
    padding-bottom: 15px;
}
.inspectionVersion .errorMessage {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    color: #e70707;
}
.noDataFound {
    display: flex;
    justify-content: center;
    margin-top: 100px;
}
