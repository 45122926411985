.remark-history__list {
  font-size: 14px;
  max-height: 140px;
  overflow-y: scroll;
}

.remark-history__listitem {
  display: flex;
  padding: 12px;
  align-items: center;
}

.remark-history__listitem:nth-child(odd) {
  background-color: #F4F4F4;
  padding: 12px;
}

.listitem__user {
  color: #0059a3;
  font-weight: 600;
  margin-right: 10px;
  width: 52px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listitem__remark {
  flex: 1;
}

.listitem__timestamp {
  display: flex;
  align-items: center;
  color: #757575;
  font-weight: 600;
  margin-left: 10px;
  font-size: 12px;
}

.remark__audioplayer {
  height: 26px;
  width: 100%;
  border: 1px solid rgba(204, 204, 204, 0.523);
  border-radius: 20px;
}