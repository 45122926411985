.carVariantHeader {
    background-color: #0059a3;
    padding: 0 20px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
}
.carVariantHeader .goBack {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    position: relative;
    padding-left: 40px;
    cursor: pointer;
}
.carVariantHeader .goBack::before {
    content: '';
    background-image: url('../../components/images/back-button.svg');
    background-repeat: no-repeat;
    width: 18px;
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
}
