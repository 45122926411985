.primaryButton {
  border-radius: 4px;
  height: 46px;
  padding: 0 20px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  background-color: #e87211;
  transition: all 0.2s;
  width: 100%;
}
.primaryButton:active {
  background-color: #e87211;
  transition: all 0.2s;
}
.primaryButton:disabled {
  background-color: #e87211;
  color: #fff;
  cursor: not-allowed;
}
.primaryButton:disabled:hover {
  background-color: #e87211;
}
.disabled {
  cursor: not-allowed;
}
.assignButton {
  height: 34px;
  border-radius: 9px;
  border: solid 1px #fff;
  background-color: transparent;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}
.assignButton:active {
  transition: all 0.2s;
}
.assignButton:disabled {
  background-color: #e87211;
  color: #fff;
  cursor: not-allowed;
}
.grayButton {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #979797;
  background-color: #fff;
  height: 26px;
  font-size: 14px;
  font-weight: 600;
  color: #707070;
}
.grayButton:active {
  transition: all 0.2s;
}
.grayButton:disabled {
  cursor: not-allowed;
}
.borderOutfitButton {
  border-radius: 4px;
  height: 46px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: bold;
  color: #e87211;
  text-transform: uppercase;
  border: 1px solid #e87211;
  background-color: #fff;
  width: 100%;
}
.secondaryButton {
  border-radius: 4px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #a0a0a0;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  padding: 0 10px;
  min-width: 140px;
  width: 100%;
  min-height: 37px;
  border: none;
}
.secondaryButton:active {
  transition: all 0.2s;
}
.secondaryButton:disabled {
  cursor: not-allowed;
}
.greenButton {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #5eb264;
  height: 45px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  padding: 0 20px 0 50px;
  position: relative;
}
.greenButton::before {
  content: '';
  background-image: url('../../images/arrow.svg');
  background-repeat: no-repeat;
  width: 21px;
  height: 15px;
  position: absolute;
  left: 20px;
  top: 15px;
}
.greenButton:active {
  transition: all 0.2s;
}
.greenButton:disabled {
  cursor: not-allowed;
}
.greenCtaButton,
.imageEditCta {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #5eb264;
  height: 45px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  position: relative;
  width: 100%;
  border: none;
}
.greenCtaButton:active,
.imageEditCta:active {
  transition: all 0.2s;
}
.greenCtaButton:disabled {
  cursor: not-allowed;
  background-color: #a9a6a6;
}
.imageEditCta:disabled {
  cursor: not-allowed;
}
.imageEditCta {
  height: 26px;
  font-size: 16px;
  width: auto;
  border-radius: 0;
  margin-right: 10px;
  padding: 0 10px 0 30px;
  border: none;
  box-shadow: none;
  min-width: 80px;
  font-weight: normal;
  text-transform: uppercase;
  position: relative;
}
.imageEditCta::before {
  content: '';
  position: absolute;
  top: 5px;
  left: 10px;
  background-image: url('../../images/pencil.svg');
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
}
.redCtaButton {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #e95f5f;
  height: 45px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  width: 100%;
}
.redCtaButton:active {
  transition: all 0.2s;
}
.redCtaButton:disabled {
  cursor: not-allowed;
}
.cancelButton {
  height: 45px;
  font-size: 18px;
  color: #707070;
  padding: 0 20px 0 50px;
  position: relative;
  background-color: transparent;
  border: none;
}
.assignCta {
  border-radius: 4px;
  padding: 4px 10px;
  border: none;
  font-size: 14px;
  color: #fff;
  text-transform: capitalize;
  background-color: #e87211;
}
.assignCta:active {
  background-color: #e87211;
  transition: all 0.2s;
}
.assignCta:disabled {
  background-color: #e87211;
  color: #fff;
  cursor: not-allowed;
}
.assignCta:disabled:hover {
  background-color: #e87211;
}
.discard {
  border: none;
  font-size: 12px;
  color: #e95f5f;
  text-transform: capitalize;
  background-color: transparent;
  position: relative;
  padding-left: 20px;
}
.discard::before {
  position: absolute;
  left: 0;
  top: 2px;
  content: '';
  background-image: url(../../images/delete.svg);
  background-repeat: no-repeat;
  width: 12px;
  height: 14px;
}
.normalCta {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  border: none;
  background-color: transparent;
}
.goBackCta {
  border-radius: 3px;
  border: solid 1px #0059a3;
  font-size: 18px;
  font-weight: bold;
  color: #0059a3;
  width: 100%;
  min-height: 45px;
}
.blueCta {
  border-radius: 30px;
  height: 46px;
  padding: 0 40px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  background-color: #0059a3;
  transition: all 0.2s;
}
.primaryButton,
.greenButton,
.greenCtaButton,
.assignButton,
.grayButton,
.secondaryButton,
.imageEditCta,
.redCtaButton,
.cancelButton,
.assignCta,
.discard,
.normalCta,
.goBackCta,
.blueCta {
  cursor: pointer;
  outline: none;
}
.primaryButton:focus,
.greenButton:focus,
.greenCtaButton:focus,
.assignButton:focus,
.grayButton:focus,
.secondaryButton:focus,
.imageEditCta:focus,
.redCtaButton:focus,
.cancelButton:focus,
.assignCta:focus,
.discard:focus,
.normalCta:focus,
.goBackCta:focus,
.blueCta:focus {
  outline: none;
}
