.redcolor, .yellowcolor, .greencolor, .orangecolor {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 40px;
  height: 40px;
}
.redcolor {
  background-color: #d92c00;
}
.yellowcolor {
  background-color: #ffba00;
}
.greencolor {
  background-color: #57b403;
}
.orangecolor {
  background-color: #ea550f;
}
