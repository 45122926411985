.container {
  padding: 0 20px;
  margin-top: 60px;
}
.container table {
  border-spacing: 0 8px;
  border-collapse: unset;
}
.container table thead tr th {
  font-size: 18px;
  font-weight: bold;
  color: #212121;
  height: 40px;
  text-align: left;
}
.container table tbody tr {
  cursor: pointer;
}
.container table tbody tr td {
  font-size: 16px;
  color: #141414;
  background-color: #edf2f7;
  height: 40px;
  padding: 10px;
}
.container table tbody tr td p {
  margin: 0;
  padding: 0;
}
