.reinspectionEmailPopup {
    width: 780px;
}
.reinspectionEmailPopup .emailForm {
    display: grid;
    grid-template-columns: 10% 90%;
    row-gap: 20px;
    margin-top: 20px;
}
.reinspectionEmailPopup .emailForm P {
    margin: 0;
    padding-top: 10px;
}
.reinspectionEmailPopup .sendCta {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.reinspectionEmailPopup .sendCta :global .greenCtaButton {
    width: 100px;
}
.reinspectionEmailPopup :global .react-multi-email {
    min-height: 40px;
    padding: 0 5px;
    border: 1px solid #ccc !important;
    line-height: 40px;
}
.reinspectionEmailPopup :global .react-multi-email > input {
    min-height: 30px;
    width: 100% !important;
    font-size: 14px;
}
.reinspectionEmailPopup :global .react-multi-email.focused {
    border: 1px solid #ccc !important;
}
