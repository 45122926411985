.imageStrip, .imageStripGrey {
    background-color: #e0ecf6;
    min-height: 46px;
    display: flex;
    align-items: center;
    padding: 2px 16px;
    margin-top: 3px;
    justify-content: space-between;
    flex: 1;
}
.imageStrip .imageDetails, .imageStripGrey .imageDetails {
    display: flex;
    align-items: center;
}
.imageStrip .stripRight, .imageStripGrey .stripRight {
    font-size: 16px;
    font-weight: bold;
    color: #000;
}
.imageStrip .lableText, .imageStripGrey .lableText {
    font-size: 16px;
    color: #000;
    padding-left: 10px;
    margin: 0;
    text-transform: capitalize;
}
.imageStripGrey {
    background-color: #f5f5f5;
}
