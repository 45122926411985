.documentInfo {
  padding: 8px 0;
  border-bottom: 1px solid #e3e3e3;
  display: flex;
  align-items: center;
}
.dataWrapper {
  margin-left: 10px;
  color: #9e9e9e;
}
.checkboxlabel {
  display: flex;
}
.checkboxlabel .lableText {
  font-size: 16px;
  color: #141414;
  padding-left: 10px;
}
.newValue {
  font-size: 11px;
  color: crimson;
  font-weight: bold;
}
.importantField {
  font-weight: bold;
}

.infoIcons {
  height: 20px;
  width: 20px;
  margin-bottom: -4px;
  margin-left: 2px;
}