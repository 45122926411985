
.radioButtonWrapper {
  align-items: center;
  margin-bottom: 10px;
}

.labelText {
  margin-left: 10px;
}

.fontWeightBold {
  font-weight: bold;
}
/* .checkboxHolder{
  border-radius: 4.5px;
  box-shadow: 0 2px 4px 0 rgba(0, 55, 255, 0.1);
  background-color: #ffffff;
  margin: 14px 0;
  padding: 0px 17px;
  height: 40px;
} */
/* .checkboxHolder:hover {
  box-shadow: 0 1px 3px 0 #0037ff;
} */
/* input[type="radio"] {
    display: none;
    margin: 0;
    padding: 0;
  }
.checkboxHolder label {
    cursor: pointer;
    position: relative;
    padding-left: 30px;
    font-size: 16px;
    color: #141414;
  }
  input[type="radio"] + label:before,
  input[type="radio"]:checked + label:before {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    position: relative;
    padding: 0;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }

  input[type="radio"] + label:before {
    background-image: url('./images/desectedRadio.svg');
  }
  input[type="radio"]:checked + label:before {
    background-image: url('./images/selectedRadio.svg');
  }
  input[type="radio"]:focus + label::before {
      outline: transparent;
  } */