.auidWrapper {
    padding: 0 20px;
}
.auidWrapper h3 {
    color: #666;
}
.auidWrapper .auditVersion {
    border: 2px solid #f76c01;
    border-radius: 100px;
    display: flex;
    margin-bottom: 20px;
}
.auidWrapper .auditVersion p {
    background-color: #0359a8;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    margin: 0;
    padding: 0;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
}
.auidWrapper .auditVersion .auditList {
    width: 85%;
}
.auidWrapper .auditVersion .auditList ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}
.auidWrapper .auditVersion .auditList ul li {
    list-style: none;
    border: 1px solid #999;
    font-size: 14px;
    color: #666;
    padding: 10px;
    margin: 5px;
    min-width: 150px;
    border-radius: 50px;
    text-align: center;
    font-weight: bold;
}
