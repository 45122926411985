.documentInfo {
  padding: 8px 0;
  border-bottom: 1px solid #e3e3e3;
}
.dataWrapper {
  width: 50%;
}
.checkboxlabel .lableText {
  font-size: 16px;
  color: #141414;
  padding-left: 10px;
}
.newValue {
  font-size: 11px;
  color: crimson;
  font-weight: bold;
}
.importantField {
  font-weight: bold;
}
