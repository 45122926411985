.chassisNo {
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 10px;
}
.chassisNo p {
    font-size: 14px;
    color: #484747;
    padding: 5px 0;
    margin: 0;
}
.detailMismatch p {
    font-size: 14px;
    color: #484747;
    padding: 5px 0;
    margin: 0;
}
.multiSelect {
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 10px;
}
