.back {
    color: #0059a3;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-items: center;
    align-items: center;
}
.back img {
    margin-right: 10px;
}
