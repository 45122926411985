.addParameterPopup {
    min-width: 780px;
    min-height: 390px;
    max-height: 400px;
}
.addParameterPopup .heading {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    color: #000;
}
.addParameterPopup .parameterList {
    margin-top: 20px;
}
.addParameterPopup .parameterList ul {
    margin: 0;
    padding: 0;
}
.addParameterPopup .parameterList ul li {
    list-style: none;
    display: flex;
    margin: 30px 0;
}
.addParameterPopup .parameterList ul li span.labelText {
    width: 200px;
}
.addParameterPopup .parameterList ul li .typeList {
    display: flex;
}
.addParameterPopup .parameterList ul li .typeList .typeListOption {
    font-size: 16px;
    color: #141414;
    margin-right: 20px;
}
.addParameterPopup .parameterList ul li .uploadList .optionIitem {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.addParameterPopup .parameterList ul li .uploadList img {
    margin-left: 10px;
    cursor: pointer;
    border: 1px solid gray;
    border-radius: 50%;
}
.addParameterPopup .parameterList ul li .uploadList input[type=file] {
    display: none;
}
.addParameterPopup .parameterList ul li .uploadList .removeButton {
    margin-left: 10px;
    width: 25px;
    height: 25px;
    border: none;
    border-radius: 500%;
    background-color: #ec1a1a;
    color: #fff;
    font-weight: 900;
    cursor: pointer;
}
.addParameterPopup .parameterList ul li .uploadList .addOption {
    font-size: 14px;
    color: #141414;
    height: 37px;
    width: 170px;
    text-align: start;
    border-radius: 5px;
    background-color: #f2eeee;
    border: none;
    cursor: pointer;
}
.addParameterPopup .parameterList ul li .uploadList .addOption:focus {
    outline: none;
}
.addParameterPopup .errorMessage {
    color: #eb1717;
    font-size: 12px;
    margin-left: 10px;
}
.addParameterPopup .greenCtaButton {
    width: 50%;
    float: right;
    margin-top: 45px;
}
.addParameterPopup .successMsg {
    color: #168016;
}
