.inspectionReviewInterior {
    padding: 0 20px;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper {
    display: flex;
    flex: 1 1 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper .ml20 {
    margin-left: 20px;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper .interiorImageWrapper {
    width: 49.2%;
    margin-top: 20px;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper .interiorImageWrapper:nth-child(3) {
    order: 3;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper .interiorImageWrapper:nth-child(2) {
    order: 2;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper .interiorImageWrapper:nth-child(4) {
    order: 4;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper .interiorImageWrapper:nth-child(5) {
    order: 5;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper .interiorImageWrapper img {
    width: 100%;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper .interiorImageWrapper :global .TransformComponent-module_content__TZU5O {
    height: 513px;
    width: 100%;
    position: relative;
    background-color: #c1c1c1 b3;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper .interiorImageWrapper :global .TransformComponent-module_content__TZU5O img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    margin: auto;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper .interiorImageWrapper .exteriorText {
    margin-top: 16px;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper .interiorImageWrapper .exteriorTextList p {
    margin: 0;
    padding-bottom: 10px;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper .interiorImageWrapper .exteriorTextList ul {
    margin: 10px 0 0 20px;
    padding: 0;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper .interiorImageWrapper .exteriorTextList ul li {
    list-style: none;
    display: flex;
    padding: 5px 0;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper .interiorImageWrapper .exteriorTextList ul li img {
    max-width: 18px;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper .interiorImageWrapper .exteriorTextList ul li p {
    padding-left: 10px;
    margin: 0;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper .interiorImageWrapper .exteriorTextList :global .inspection-car-details-comments_checkboxlabel__3GpaN {
    display: flex;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper .interiorContentWrapper {
    background-color: rgba(0, 89, 163, 0.05);
    width: 49.2%;
    margin-bottom: 1.2%;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper .interiorContentWrapper ul {
    margin: 0;
    padding: 0;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper .interiorContentWrapper ul li {
    list-style: none;
    align-items: center;
    padding: 0px 10px 0 0;
    min-height: 42px;
    border-bottom: 1px solid #e3e3e3;
    font-size: 16px;
    color: #000;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper .interiorContentWrapper ul li .checkboxContent p {
    padding-left: 10px;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper .interiorContentWrapper ul li p {
    margin: 0;
    padding: 0;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper .interiorContentWrapper ul li .yes, .inspectionReviewInterior .inspectionReviewInteriorWrapper .interiorContentWrapper ul li .none {
    font-size: 18px;
    font-weight: bold;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper .interiorContentWrapper ul li .yes {
    color: #5eb264;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper .interiorContentWrapper ul li .none {
    color: #535353;
}
.inspectionReviewInterior .inspectionReviewInteriorWrapper .interiorContentWrapper ul li:last-child {
    border-bottom: none;
}
.greyStrip {
    background-color: #f5f5f5;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    margin-top: 3px;
}
.greyStrip img {
    width: 18px !important;
    height: 18px;
}
.greyStrip p {
    margin: 0;
    font-size: 16px;
    color: #000;
    padding-left: 10px;
    text-transform: capitalize;
}
.interiorTesting :global .inspection-interior_documentInfo__3bqZJ {
    font-weight: bold;
    margin-left: 6px;
    justify-content: start;
}
.interiorTesting :global .inspection-interior_documentInfo__3bqZJ span {
    padding-left: 4px;
}
.interiorTesting :global .inspection-review-interior_yes__31l1o {
    line-height: 20px;
    font-size: 14px;
    padding-left: 46px;
}
.interiorTesting :global .inspection-review-interior_exteriorTextList__ALPCv p {
    font-weight: bold;
}
