.backSearchWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: #edf2f7;
}
.inspectionVersion .dataTable {
    padding: 30px 20px 0;
}
.inspectionVersion .dataTable table {
    margin: auto;
    border-collapse: collapse;
    overflow-x: auto;
    display: block;
    width: fit-content;
    max-width: 100%;
}
.inspectionVersion .dataTable table thead th {
    text-align: left;
    padding: 0 15px 10px 15px;
    padding-bottom: 15px;
}
.inspectionVersion .dataTable table tbody tr td {
    font-size: 14px;
    padding: 0 15px 0px 15px;
    padding-bottom: 15px;
}
