.inspectionReviewTabs ul {
    display: flex;
}
.inspectionReviewTabs ul li {
    height: 32px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    list-style: none;
    text-transform: uppercase;
    padding: 0 15px;
    margin-right: 10px;
    cursor: pointer;
}
.inspectionReviewTabs ul li:hover, .inspectionReviewTabs ul li.active {
    background-color: #0059a3 !important;
    color: #fff !important;
    font-size: 12px;
    border-radius: 16px;
}
.inspectionReviewTabs ul li:hover span, .inspectionReviewTabs ul li.active span {
    color: #fff !important;
    background-color: #0059a3 !important;
}
.inspectionReviewTabs ul li.notactive {
    font-size: 12px;
    border-radius: 16px;
    color: black;
}
.inspectionReviewTabs ul li.notactive span {
    color: black;
}
.inspectionReviewTabs ul li span {
    width: 16px;
    height: 16px;
    margin-left: 5px;
    font-size: 10px;
    font-weight: 500;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.inspectionReviewTabs ul li:last-child {
    margin-right: 0px;
}
