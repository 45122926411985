.loginMainWrapper {
    background-color: rgba(5, 114, 205, 1);
    background-color: -moz-linear-gradient(top, rgba(5, 114, 205, 1) 0%, rgba(0, 89, 163, 1) 99%, rgba(0, 89, 163, 1) 100%);
    background-color: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(5, 114, 205, 1)), color-stop(99%, rgba(0, 89, 163, 1)), color-stop(100%, rgba(0, 89, 163, 1)));
    background-color: -webkit-linear-gradient(top, rgba(5, 114, 205, 1) 0%, rgba(0, 89, 163, 1) 99%, rgba(0, 89, 163, 1) 100%);
    background-color: -o-linear-gradient(top, rgba(5, 114, 205, 1) 0%, rgba(0, 89, 163, 1) 99%, rgba(0, 89, 163, 1) 100%);
    background-color: -ms-linear-gradient(top, rgba(5, 114, 205, 1) 0%, rgba(0, 89, 163, 1) 99%, rgba(0, 89, 163, 1) 100%);
    background-color: linear-gradient(to bottom, rgba(5, 114, 205, 1) 0%, rgba(0, 89, 163, 1) 99%, rgba(0, 89, 163, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0572cd', endColorstr='#0059a3', GradientType=0);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: scroll;
}
.loginMainWrapper::after {
    content: '';
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('../../components/images/line.svg');
    background-repeat: repeat;
    height: 32px;
}
.loginMainWrapper .loginWrapper {
    width: 470px;
    margin: 20px auto;
    border-radius: 13px;
    box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    padding-bottom: 40px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9;
}
.loginMainWrapper .loginWrapper .logoSection {
    display: flex;
    justify-content: center;
    padding-top: 36px;
}
.loginMainWrapper .loginWrapper .logoSection img {
    margin: 0 10px;
}
.loginMainWrapper .loginWrapper .text {
    font-style: italic;
    color: #0059a3;
    font-size: 16px;
    text-align: center;
    margin: 0;
    padding-top: 16px;
    font-weight: 600;
}
.loginMainWrapper .loginWrapper .loginButton {
    background-color: #4285f4;
    border-radius: 7px;
    box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 0.2);
    height: 72px;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    margin: 40px auto 0;
    padding: 0;
    display: block;
}
.loginMainWrapper .loginWrapper .loginButton span {
    position: relative;
    padding: 0 30px 0 70px;
}
.loginMainWrapper .loginWrapper .loginButton span::before {
    background-image: url('../../components/images/gmail.jpg');
    background-repeat: no-repeat;
    width: 58px;
    content: '';
    position: absolute;
    top: -20px;
    left: 4px;
    height: 61px;
    background-size: 100%;
    border-bottom-left-radius: 7px;
    border-top-left-radius: 7px;
}
.loginMainWrapper .loginWrapper .loginButton:focus {
    outline: none;
    border: none;
}
.loginMainWrapper .loginWrapper .signUplink {
    text-align: center;
    margin-top: 28px;
    padding-bottom: 12px;
}
.loginMainWrapper .loginWrapper .signUplink a {
    font-size: 16px;
    font-weight: 500;
    color: #e87211;
}
.loginMainWrapper .loginWrapper .signUplink p {
    margin: 0;
    padding-top: 2px;
    font-size: 14px;
    color: #e87211;
}
.loginMainWrapper .loginWrapper .loginSection {
    border-top: 1px solid #a7a7a7;
    padding: 12px 70px 0;
}
.loginMainWrapper .loginWrapper .loginSection label {
    font-size: 16px;
    color: #646464;
    margin: 10px 0 8px 0;
    display: block;
}
.loginMainWrapper .loginWrapper .loginSection .forgetPassword {
    opacity: 0.78;
    font-size: 14px;
    text-align: right;
    color: #646464;
    text-decoration: none;
    display: flex;
    justify-content: flex-end;
    padding: 4px 0 22px 0;
}
.loginMainWrapper .loginWrapper .loginSection .textDanger {
    color: red;
    font-size: 12px;
    padding-top: 4px;
}
.loginMainWrapper .loginWrapper .googleLogin {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-top: 20px;
}
