.inspectionReviewFooter {
    margin-top: 70px;
}
.inspectionReviewFooter .comments {
    padding: 0 20px;
}
.inspectionReviewFooter .comments h3 {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    color: #000;
}
.inspectionReviewFooter .comments textarea {
    border-radius: 10px;
    border: solid 1px #d0dce7;
    background-color: #f3f3f3;
    padding: 10px;
    width: 50%;
}
.inspectionReviewFooter .comments textarea:focus {
    outline: none;
}
.inspectionReviewFooter .footer {
    background-color: #e0ecf6;
    padding: 50px 20px;
    margin: 60px 0 0 0;
    display: flex;
    align-items: center;
}
.inspectionReviewFooter .footer .contactSection {
    flex: 1 1 60%;
}
.inspectionReviewFooter .footer .contactSection p {
    margin: 0;
    padding: 4px 0;
    font-size: 18px;
    font-weight: 500;
    color: #404040;
}
.inspectionReviewFooter .footer .contactSection .contact {
    padding-bottom: 10px;
}
.inspectionReviewFooter .footer .criticalSection {
    flex: 1 1 20%;
}
.inspectionReviewFooter .footer .criticalSection ul {
    margin: 0;
    padding: 0;
}
.inspectionReviewFooter .footer .criticalSection ul li {
    list-style: none;
    display: flex;
    padding: 4px 0;
    font-weight: bold;
}
.inspectionReviewFooter .footer .criticalSection ul li span {
    padding-left: 10px;
}
.inspectionReviewFooter .footer .submitSection {
    flex: 1 1 20%;
}
.inspectionReviewFooter .footer .submitSection .submitText {
    font-size: 12px;
    color: #212121;
    margin: 0;
    padding-top: 6px;
}
.lableText {
    margin-left: 10px;
}
