.carouselTextWrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    margin-top: 70px;
}
.carouselTextWrapper .carousel {
    flex: 1 1 60%;
    width: 60%;
    position: relative;
}
.carouselTextWrapper .carouselContent {
    flex: 1 2 40%;
    margin-left: 20px;
}
.carouselTextWrapper .carouselContent .rcTextWrapper {
    position: relative;
    padding-top: 10px;
}
.carouselTextWrapper .carouselContent .rcTextWrapper .qcRequiredWrapper::before {
    content: '';
    border-top: 4px solid #e87211;
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}
.carouselTextWrapper .carouselContent .rcTextWrapper .qcRequiredWrapper .qcRequired {
    margin: 0;
    background-color: #e87211;
    color: #fff;
    font-size: 14px;
    width: 85px;
    height: 34px;
    padding: 8px;
    position: absolute;
    top: 0px;
    right: -3px;
    border-radius: 8px;
    text-align: center;
    border-top-left-radius: 0;
}
.carouselTextWrapper .carouselContent .rcTextWrapper .qcAuditInstruction {
    background-color: #e87211;
    color: #fff;
    font-size: 14px;
    padding: 8px;
    border-radius: 8px;
    text-align: center;
}
.carouselTextWrapper .carouselContent .text {
    font-size: 12px;
    font-weight: bold;
    color: #a6a6a6;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}
.carouselTextWrapper .carouselContent .text .checkbox {
    margin: 0 5px;
}
.carouselTextWrapper .carouselContent .text .info {
    padding: 0 5px;
    display: flex;
}
.carouselTextWrapper .carouselContent .text .checkboxlabel {
    display: flex;
}
.carouselTextWrapper .carouselContent .text .checkboxlabel .lableText {
    font-size: 16px;
    color: #141414;
    padding-left: 10px;
}
.carouselTextWrapper .carouselContent .text .checkboxlabel .noMismatch {
    font-size: 16px;
    color: #141414;
    padding-left: 10px;
    font-weight: bold;
}
.infoSection {
    margin-top: 32px;
}
.misMatch {
    margin-top: 14px;
}
.misMatchSection {
    margin-top: 14px;
}
.misMatchSection ul {
    margin: 0;
    padding: 0;
}
.misMatchSection ul li {
    list-style: none;
    align-items: center;
    display: flex;
    padding: 8px 0;
    border-bottom: 1px solid #e3e3e3;
}
.misMatchSection ul li p {
    margin: 0;
    padding: 0px;
    flex: 1 2 50%;
}
.misMatchSection ul li .carsinfo {
    flex: 1 1 50%;
}
.misMatchSection ul li .checkboxlabel {
    display: flex;
    flex: 1 1 50%;
}
.misMatchSection ul li .checkboxlabel .lableText {
    font-size: 16px;
    color: #141414;
    padding-left: 10px;
}
.misMatchSection ul li:last-child {
    border-bottom: none;
}
