.carouselWrapper {
    width: 100%;
    position: relative;
}
.carouselWrapper button {
    border: none;
    cursor: pointer;
    padding: 0;
}
.carouselWrapper button:focus {
    outline: none;
}
.nextPrev {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: transparent;
    padding: 0;
}
.nextPrev img {
    width: 28px;
}
.nextPrev button {
    margin-left: 10px;
}
.prev {
    cursor: pointer;
}
.imgCaption {
    font-size: 16px;
    color: #141414;
    position: absolute;
    bottom: 10px;
    right: 150px;
}
.imageWrapper {
    position: relative;
}
.imageWrapper .refurbishImageTitle {
    background-color: #e0ecf6;
    margin: 0;
    padding: 15px;
}
.zoomWrapper {
    position: absolute;
    bottom: 7px;
    left: 10px;
    z-index: 1;
    display: flex;
}
.rotation {
    background: none;
    margin-left: 0px;
    padding: 0;
}
.rotationImg {
    transform: scaleX(-1);
    background: none;
    margin-bottom: 5px;
}
.carouselWrapper :global .TransformComponent-module_container__3NwNd {
    width: 100%;
}
.carouselWrapper :global .TransformComponent-module_content__TZU5O {
    width: 100%;
    background-color: #c1c1c1 b3;
    height: 500px;
    position: relative;
}
.carouselWrapper :global .TransformComponent-module_content__TZU5O img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    margin: auto;
}
.additionalWrapper {
    display: flex;
    position: absolute;
    right: 12px;
    top: -14px;
    z-index: 1;
}
.additionalWrapper .rc, .additionalWrapper .additional, .additionalWrapper .insurance {
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 28px;
    font-weight: bold;
    margin: 0;
    font-size: 16px;
}
.additionalWrapper .rc span, .additionalWrapper .additional span, .additionalWrapper .insurance span {
    min-width: 15px;
    min-height: 15px;
    border-radius: 100%;
    display: inline-flex;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    font-weight: normal;
}
.additionalWrapper .rc {
    background-color: #0059a3;
    color: #fff;
    min-width: 80px;
}
.additionalWrapper .rc span {
    color: #fff;
    background-color: #5ea9ea 61;
}
.additionalWrapper .insurance {
    background-color: #0059a3;
    color: #fff;
    min-width: 80px;
    padding-right: 12px;
    padding-left: 12px;
}
.additionalWrapper .insurance span {
    color: #fff;
    background-color: #5ea9ea 61;
}
.additionalWrapper .additional {
    background-color: #d8d8d8;
    color: #141414;
    min-width: 130px;
    margin: 0 0 0 12px;
}
.additionalWrapper .additional span {
    color: #141414;
    background-color: #b1afaf;
}
