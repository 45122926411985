.rejectionPopup {
    min-width: 780px;
}
.rejectionPopup .multiselect {
    height: 250px;
    margin-top: 14px;
}
.rejectionPopup .ctaWrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 10px;
}
.rejectionPopup :global .optionContainer {
    max-height: 180px;
}
