.inspectionReviewFooter {
    margin-top: 70px;
}
.inspectionReviewFooter .comments {
    padding: 0 20px;
}
.inspectionReviewFooter .comments h3 {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    color: #000;
}
.inspectionReviewFooter .comments textarea {
    border-radius: 10px;
    border: solid 1px #d0dce7;
    background-color: #f3f3f3;
    padding: 10px;
    width: 50%;
}
.inspectionReviewFooter .comments textarea:focus {
    outline: none;
}
.inspectionReviewFooter .cjRemarks {
    padding: 0 20px 10px 20px;
}
.inspectionReviewFooter .cjRemarks h3 {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    color: #000;
}
.inspectionReviewFooter .cjRemarks .listRow {
    line-height: 24px;
    font-size: 12px;
    display: block;
}
.inspectionReviewFooter .footer {
    background-color: #e0ecf6;
    padding: 50px 20px;
    margin: 60px 0 0;
    display: flex;
    justify-content: flex-end;
}
.inspectionReviewFooter .footer .ctaWrapper {
    width: 40%;
    display: flex;
    justify-content: right;
}
.inspectionReviewFooter .footer .ctaWrapper :global .secondaryButton {
    margin-right: 10px;
    text-transform: uppercase;
}
.inspectionReviewFooter .footer .loader img {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}
