.carouselContent {
    flex: 1 2 40%;
    margin-left: 20px;
    height: 500px;
    overflow: auto;
}
.carouselContent .text {
    font-size: 12px;
    font-weight: bold;
    color: #a6a6a6;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}
.carouselContent .text .checkbox {
    margin: 0 5px;
}
.carouselContent .text .info {
    padding: 0 5px;
    display: flex;
}
.heading, .active {
    position: relative;
    font-size: 16px;
    color: #0059a3;
    font-weight: bold;
    padding-left: 26px;
    margin: 30px 0 15px 0;
}
.heading::before, .active::before {
    content: '';
    background-image: url('../images/arrowToggle.svg');
    background-repeat: no-repeat;
    width: 20px;
    height: 12px;
    position: absolute;
    top: 3px;
    left: 0;
    transform: rotate(-90deg);
}
.heading.active::before, .active.active::before {
    content: '';
    background-image: url('../images/arrowToggle.svg');
    transform: rotate(0);
}
.heading .headingtext, .active .headingtext {
    margin: 0;
    padding: 0;
}
