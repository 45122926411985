.imageSection {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.heading {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
  color: #465166;
}
.descroption {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: center;
  color: rgba(70, 81, 102, 0.62);
  padding: 0 64px;
  margin: 12px 0px 48px 0px;
}
.bottomSection {
  border-top: 1px solid #dde5eb;
}
.bottomSection p {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #465166;
}
@media screen and (min-width: 600px) {
  .callSection {
    margin-bottom: 150px;
 }
}
