body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas,'Roboto', 'Courier New',
    monospace;
}

.mb-lg {
  margin-bottom: 20px !important;
}

.Toastify__toast-container {
  z-index: 999999 !important;
}

.Toastify__toast-body {
  white-space: pre-line;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.remarkHistoryBtn {
  font-size: 14px;
  color: #0059a3;
  cursor: pointer;
  font-weight: 600;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: #eb080892 0px 0px 0px 0px;
  }

  70% {
      transform: scale(1);
      box-shadow: #eb08085e 0px 0px 0px .8rem;
  }

  100% {
      transform: scale(0.95);
      box-shadow: #eb08081a 0px 0px 0px 0px;
  }
}