.carVariantLeftPanel {
    width: 72%;
    margin-top: 50px;
}
.carVariantLeftPanel .variantTopModle ul {
    margin: 0;
    padding: 0;
    width: 70%;
}
.carVariantLeftPanel .variantTopModle ul li {
    list-style: none;
    border-bottom: 1px solid #d4d8e3;
    display: flex;
    align-items: center;
    margin: 5px 0 5px 0;
    padding: 0px 0 5px 0;
    min-height: 40px;
}
.carVariantLeftPanel .variantTopModle ul li p {
    margin: 0;
    padding: 0;
    width: 30%;
}
.carVariantLeftPanel .variantTopModle ul li .variantBox {
    display: flex;
}
.carVariantLeftPanel .variantTopModle ul li .variantBox .variant {
    margin-right: 30px;
}
.carVariantLeftPanel .errorMessage {
    font-size: 12px;
    color: #e21919;
    margin-left: 10px;
}
.carVariantLeftPanel .dropWrapper {
    background-color: #f3faff;
    padding: 20px;
    margin-top: 40px;
    min-height: 200px;
}
.carVariantLeftPanel .dropWrapper .drop {
    border-top: 1px solid #c0d0de;
    border-left: 1px solid #c0d0de;
    border-right: 1px solid #c0d0de;
    margin-bottom: 40px;
    background-color: #fff;
    position: relative;
}
.carVariantLeftPanel .dropWrapper .drop .variantCountLabel {
    position: absolute;
    left: -15px;
    top: -15px;
    color: #fff;
    background-color: #0059a3;
    border: none;
    font-size: 18px;
    font-weight: bold;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.carVariantLeftPanel .dropWrapper .drop .removeVariant {
    position: absolute;
    right: -15px;
    top: -15px;
    color: #fff;
    background-color: #c91313;
    border: none;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 50%;
    width: 30px;
    height: 30px;
}
.carVariantLeftPanel .dropWrapper .drop ul {
    margin: 0;
    padding: 0;
}
.carVariantLeftPanel .dropWrapper .drop ul li {
    list-style: none;
    min-height: 46px;
}
.carVariantLeftPanel .dropWrapper .drop ul li ul.dropList {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}
.carVariantLeftPanel .dropWrapper .drop ul li ul.dropList li {
    list-style: none;
    font-size: 16px;
    color: #141414;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    border-bottom: 1px solid #c0d0de;
    border-right: 1px solid #c0d0de;
}
.carVariantLeftPanel .dropWrapper .drop ul li ul.dropList li:first-child {
    width: 15%;
}
.carVariantLeftPanel .dropWrapper .drop ul li ul.dropList li:last-child {
    border-right: none;
    width: 35%;
}
.carVariantLeftPanel .dropWrapper .drop ul li.variant {
    border-bottom: 1px solid #c0d0de;
    display: flex;
    align-items: center;
    padding-left: 20px;
}
.carVariantLeftPanel .dropWrapper .drop ul li.variant p {
    margin: 0;
    padding: 0 50px;
}
.carVariantLeftPanel .dropWrapper .addVariant {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
}
.carVariantLeftPanel .dropWrapper .addVariant button {
    font-size: 16px;
    font-weight: bold;
    color: #0059a3;
    height: 42px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px #beccd7;
    background-color: #fff;
    padding: 0 30px;
    cursor: pointer;
}
.carVariantLeftPanel .dropWrapper .addVariant button:focus {
    outline: none;
}
.carVariantLeftPanel .widgetBox {
    font-size: 18px;
    font-weight: bold;
    color: #888;
    min-height: 38px;
    border-radius: 6px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #ddd;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 0 10px 0 40px;
    position: relative;
    width: 84%;
    justify-content: space-between;
}
.carVariantLeftPanel .widgetBox::before {
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    bottom: 0;
    margin: auto;
    background-image: url(../images/widgetDots.svg);
    background-repeat: no-repeat;
    width: 12px;
    height: 13px;
}
.carVariantLeftPanel .placeHolderColor {
    color: #888;
}
.carVariantLeftPanel .image {
    width: 100px;
    height: 40px;
    margin-left: 10px;
}
