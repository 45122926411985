.locationWrapper {
    display: flex;
}
.locationWrapper .boxSelect {
    min-width: 140px;
    margin-right: 10px;
}
.locationWrapper :global #multiselectContainerReact {
    background-color: #fff;
    min-height: 38px;
    max-width: 250px;
}
.locationWrapper :global #multiselectContainerReact > div {
    min-height: 26px;
}
.locationWrapper :global #multiselectContainerReact > div input {
    font-size: 14px;
}
