.interiorContentWrapper {
    background-color: rgba(0, 89, 163, 0.05);
}
.interiorContentWrapper ul {
    margin: 0;
    padding: 0;
}
.interiorContentWrapper ul li {
    list-style: none;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0px 15px;
    height: 42px;
    border-bottom: 1px solid #e3e3e3;
    font-size: 16px;
    color: #000;
}
.interiorContentWrapper ul li .checkboxContent {
    display: flex;
}
.interiorContentWrapper ul li .checkboxContent p {
    padding-left: 10px;
}
.interiorContentWrapper ul li p {
    margin: 0;
    padding: 0;
}
.interiorContentWrapper ul li .yes, .interiorContentWrapper ul li .none {
    font-size: 18px;
    font-weight: bold;
}
.interiorContentWrapper ul li .yes {
    color: #5eb264;
}
.interiorContentWrapper ul li .none {
    color: #535353;
}
.documentInfo {
    display: flex;
    justify-content: space-between;
    flex: 1;
    border-left: 4px solid transparent;
}
.documentInfo .checkboxlabel {
    display: flex;
}
.documentInfo label {
    padding-left: 10px;
}
.documentInfo .dataWrapper {
    display: flex;
}
.documentInfo .dataWrapper .newValue {
    background-color: #000 2e;
    color: #0059a3;
    font-size: 14px;
    font-style: italic;
    border-radius: 12px;
    padding: 2px 8px;
    min-width: 50px;
    margin-left: 5px;
}
.borderHighlight {
    border-left-color: #0059a3;
}
.strong {
    font-weight: bold;
}
